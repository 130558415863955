/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IMAGES, SVGICON } from '../../constant/theme';
import MainPagetitle from '../../layouts/MainPagetitle';
import { Card, Col, Dropdown, Nav, Row, Tab } from 'react-bootstrap';
import {
    CountryAvailable, CountryCodes, QueryParamsKey, UserGroupDefault,
    VendorIdDefault, VerificationStatus, VerificationStatusList, CustomerTabKeys, SpecialVendors,
    AllTabsCustomerForVendor, RegionsGroupDefault, ACCEPT_BUSINESS_LICENSE_FILE,
    LocationFormat, TabsCustomerAccounts, OptionMenuSubUser, ActionOnRecord,
    TabsCustomerNonRefID, TabsCustomerRefID, AllTabsCustomerInfo,
    DOCS_FILE_EXTENSION,
    RoleOfSubUserDefault,
    NatureOfBusinessDefault,
    DesignationRequireLicenseFile,
    PrefixDefault,
    SpecialtyDefault,
    AccessLevels
} from '../../constant/AppConstant';
import locationData from '../../../assets/location-vn.json';
import statesMYData from '../../../assets/state-my.json';
import {
    filterCities, filterDistricts, filterStates, filterWards, findCityByCode, findCountryByCode,
    findDistrictByCode, findStateByCode, findWardByCode, formatDistrictWard
} from '../../../helper/location-helper';
import { CustomerInfoModel, FormDataRequest } from '../../models/customer-info-model';
import { BillingInfoModel, LocCodesModel, ProfileInfoModel, ShippingInfoModel } from '../../models/profile-model';
import {
    generateKey, hasDifferent, makeEmailFormatHint, makeMobileNumberFormatHint, reFormatPhoneNumber, showToast,
    showToastError, showToastSuccess, showToastWarning, validateEmail, validatePhoneNumber
} from '../../../helper/helper';
import { RefIdModel, UnifiedCustomerModel } from '../../models/unified-customer.model';
import RenderRefId from './RefId';
import CustomersService from '../../../services/CustomersService';
import VendorsService from '../../../services/VendorsService';
import Preloader from '../../layouts/Preloader';
import AssetsService from '../../../services/AssetsService';
import { isRoleAdmin, isRoleSuperAdmin, userDetails } from '../../../services/AuthService';
import { TextApp, TextKey } from '../../../assets/text-app';
import { SubUserModel } from '../../models/sub-user-model';
import PopupConfirmation from '../../../helper/popup-confirmation';
import AddSubUser from './AddSubUser';
import axios from 'axios';

const EditCustomer = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const countries = CountryAvailable;
    const initialized = useRef(false);
    const isAdmin = isRoleAdmin();
    const isSuperAdmin = isRoleSuperAdmin();
    const userLogged = userDetails();
    const [userGroup, setUserGroup] = useState(UserGroupDefault.ListAvailable());
    const [prefixList, setPrefixList] = useState(PrefixDefault);
    const [regionsGroup, setRegionsGroup] = useState(RegionsGroupDefault.ListAvailable());
    const [natureOfBusinessGroup, setNatureOfBusinessGroup] = useState(NatureOfBusinessDefault.ListAvailable());
    const [specialtyList, setSpecialtyList] = useState(SpecialtyDefault.ListAvailable());
    const [loading, setLoading] = useState(false);
    const [enableEnterManualProfileAddress, setEnableEnterManualProfileAddress] = useState(true);
    // const [enableEnterManualShippingAddress, setEnableEnterManualShippingAddress] = useState(false);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [wards, setWards] = useState([]);
    // Billing location
    const [billingStates, setBillingStates] = useState([]);
    const [billingCities, setBillingCities] = useState([]);
    const [billingDistricts, setBillingDistricts] = useState([]);
    const [billingWards, setBillingWards] = useState([]);
    // Shipping location
    const [shippingStates, setShippingStates] = useState([]);
    const [shippingCities, setShippingCities] = useState([]);
    const [shippingDistricts, setShippingDistricts] = useState([]);
    const [shippingWards, setShippingWards] = useState([]);
    const [tabsCustomerInfo, setTabsCustomerInfo] = useState(AllTabsCustomerForVendor);
    // Vendors
    const [vendors, setVendors] = useState([]);
    // Sub-users
    const deleteSubUserConfirmation = useRef();
    const subuserRef = useRef();
    const [subusers, setSubusers] = useState([]);
    const [newSubusersKYC, setNewSubusersKYC] = useState([]);     // Request KYC
    // Form
    let errorObj = new CustomerInfoModel();
    const [errors, setErrors] = useState(errorObj);
    const [editUnifiedCustomer, setEditUnifiedCustomer] = useState(null);
    const [formCustomerData, setFormCustomerData] = useState(new CustomerInfoModel(userLogged && userLogged.vendorId ? userLogged.vendorId : VendorIdDefault));
    const [formProfile, setFormProfile] = useState(new ProfileInfoModel());
    const [formBilling, setFormBilling] = useState(new BillingInfoModel());
    const [formShipping, setFormShipping] = useState(new ShippingInfoModel());
    const [refIds, setRefIds] = useState([]);
    const [businessLicenseFile, setBusinessLicenseFile] = useState();
    const [pharmaBusinessLicenseFile, setPharmaBusinessLicenseFile] = useState();
    const [apcLicenseFile, setApcLicenseFile] = useState();
    const [poisonALicenseFile, setPoisonALicenseFile] = useState();
    const [verification, setVerification] = useState('');
    // const [status, setStatus] = useState('');
    const [displayFileURL, setDisplayFileURL] = useState('');
    // Customer info
    const [oldUCInfo, setOldUCInfo] = useState();
    const [refreshForm, setRefreshForm] = useState(false);
    // extra note for customer
    const [note, setNote] = useState('');
    const [isRequirePoisonLicense, setIsRequirePoisonLicense] = useState(false);
    const [isRequireAPCLicense, setIsRequireAPCLicense] = useState(false);

    const closeToggle = () => {
        setDisplayFileURL('');
    }

    const isEnableAddSubUser = () => {
        return editUnifiedCustomer && editUnifiedCustomer.verification === VerificationStatus.APPROVED && editUnifiedCustomer.pomId != null && editUnifiedCustomer.pomId !== '';
    };

    /**
     * Load all states in Malaysia
     */
    const loadStates = async () => {
        try {
            const allStates = filterStates(statesMYData);
            setStates(allStates);
            setBillingStates(allStates);
            setShippingStates(allStates);
        } catch (error) {
            console.warn(error);
        }
    };

    // /**
    //  * Load all cities in Viet Nam
    //  */
    // const loadCities = async () => {
    //     try {
    //         const allCities = filterCities(locationData)
    //         setCities(allCities);
    //         setBillingCities(allCities);
    //         setShippingCities(allCities);
    //     } catch (error) {
    //         // console.warn(error);
    //     }
    // };

    const handleInputFormInfo = (e) => {
        const fieldName = e.target.name;
        const fieldValue = e.target.value;

        setFormCustomerData((prevState) => ({
            ...prevState,
            [fieldName]: fieldValue
        }));
    }

    const handleInputFormProfile = (e) => {
        const fieldName = e.target.name;
        const fieldValue = e.target.value;

        setFormProfile((prevState) => ({
            ...prevState,
            [fieldName]: fieldValue
        }));
    }

    const handleInputFormProfileNoSpace = (e) => {
        const fieldName = e.target.name;
        const fieldValue = e.target.value;
        validateNoSpace(fieldValue);

        setFormProfile((prevState) => ({
            ...prevState,
            [fieldName]: fieldValue.replace(/\s+/g, "")
        }));
    }

    const handleInputFormBillingNoSpace = (e) => {
        const fieldName = e.target.name;
        const fieldValue = e.target.value;
        validateNoSpace(fieldValue);

        setFormBilling((prevState) => ({
            ...prevState,
            [fieldName]: fieldValue.replace(/\s+/g, "")
        }));
    }

    const handleInputFormInfoNoSpace = (e) => {
        const fieldName = e.target.name;
        const fieldValue = e.target.value;
        validateNoSpace(fieldValue);

        setFormCustomerData((prevState) => ({
            ...prevState,
            [fieldName]: fieldValue.replace(/\s+/g, "")
        }));
    }

    const handleInputFormShippingNoSpace = (e) => {
        const fieldName = e.target.name;
        const fieldValue = e.target.value;
        validateNoSpace(fieldValue);

        setFormShipping((prevState) => ({
            ...prevState,
            [fieldName]: fieldValue.replace(/\s+/g, "")
        }));
    }

    const validateNoSpace = (fieldValue) => {
        const tmpStr = fieldValue.split(' ');

        if (tmpStr && tmpStr.length > 1) {
            showToastWarning(TextApp.getText(TextKey.not_contain_spaces));
        }
    }

    const handleChooseBusinessLicenseFile = (e) => {
        setBusinessLicenseFile(e.target.files[0]);
    }

    const handleChoosePharmaBusinessLicenseFile = (e) => {
        setPharmaBusinessLicenseFile(e.target.files[0]);
    }

    const handleChooseAPCFile = (e) => {
        setApcLicenseFile(e.target.files[0]);
    }

    const handleChoosePoisonALicenseFile = (e) => {
        setPoisonALicenseFile(e.target.files[0]);
    }

    const handleInputFormBilling = (e) => {
        const fieldName = e.target.name;
        const fieldValue = e.target.value;

        setFormBilling((prevState) => ({
            ...prevState,
            [fieldName]: fieldValue
        }));
    }

    const handleInputFormShipping = (e) => {
        const fieldName = e.target.name;
        const fieldValue = e.target.value;

        setFormShipping((prevState) => ({
            ...prevState,
            [fieldName]: fieldValue
        }));
    }

    const chooseProfileCountry = (value) => {
        // Profile
        formProfile.locCodes = new LocCodesModel();
        formProfile.locCodes.countryCode = value;
        formProfile.city = '';
        formProfile.state = '';
        formProfile.postalCode = '';
        // Billing
        formBilling.locCodes = new LocCodesModel();
        formBilling.locCodes.countryCode = value;
        formBilling.city = '';
        formBilling.state = '';
        // Shipping
        formShipping.locCodes = new LocCodesModel();
        formShipping.locCodes.countryCode = value;
        formShipping.city = '';
        formShipping.state = '';
        formShipping.postalCode = '';
        setEnableEnterManualProfileAddress(value !== CountryCodes.VIETNAM_COUNTRY_CODE);
        chooseProfileCity('');
        chooseBillingCity('');
        chooseShippingCity('');

        if (value) {
            const countrySelected = findCountryByCode(countries, value);

            if (countrySelected) {
                formProfile.country = countrySelected.name;
                formShipping.country = countrySelected.name;
            }
        }
    };

    // const chooseShippingCountry = (value) => {
    //     formShipping.locCodes = new LocCodesModel();
    //     formShipping.locCodes.countryCode = value;
    //     formShipping.city = '';
    //     formShipping.state = '';
    //     formShipping.postalCode = '';
    //     setEnableEnterManualShippingAddress(value !== CountryCodes.VIETNAM_COUNTRY_CODE);
    //     chooseShippingCity('');

    //     if (value) {
    //         const countrySelected = findCountryByCode(countries, value);

    //         if (countrySelected) {
    //             formShipping.country = countrySelected.name;
    //         }
    //     }
    // };

    const chooseProfileState = (value) => {
        formProfile.state = '';
        formProfile.locCodes.stateCode = value;
        const stateSelected = findStateByCode(states, value);

        if (stateSelected) {
            formProfile.state = stateSelected.NameState;
        }

        setRefreshForm(!refreshForm);
    };

    const chooseProfileCity = (value) => {
        setDistricts([]);
        setWards([]);
        formProfile.locCodes.cityCode = value;
        formProfile.locCodes.districtCode = '';
        formProfile.locCodes.wardCode = '';
        formProfile.city = ''
        formProfile.district = ''
        formProfile.ward = ''

        if (value) {
            const citySelected = findCityByCode(cities, value);

            if (citySelected) {
                formProfile.city = citySelected.NameCity;
            }

            const result = filterDistricts(locationData, value);
            setDistricts(result);
        }
    };

    const chooseProfileDistrict = (value) => {
        setWards([]);
        formProfile.locCodes.districtCode = value;
        formProfile.locCodes.wardCode = '';
        formProfile.district = ''
        formProfile.ward = ''

        if (value) {
            const districtSelected = findDistrictByCode(districts, value);;

            if (districtSelected) {
                formProfile.district = districtSelected.NameDistrict;
            }

            const result = filterWards(locationData, value);
            setWards(result);
        }
    };

    const chooseProfileWard = (value) => {
        formProfile.locCodes.wardCode = value;
        formBilling.ward = '';
        const wardSelected = findWardByCode(wards, value);

        if (wardSelected) {
            formProfile.ward = wardSelected.NameWard;
            setWards([...wards]);
        }

        setRefreshForm(!refreshForm);
    };

    const chooseForensicGroup = (value) => {
        formCustomerData.userGroup = value;
        setUserGroup([...userGroup]);
    };

    const choosePrefix = (value) => {
        formCustomerData.prefix = value;
        setPrefixList([...prefixList]);
    };

    const chooseNatureOfBusiness = (value) => {
        formCustomerData.natureOfBusiness = value;
        // setRegionsGroup([...regionsGroup]);

        // Reset region group (customer_group)
        // let natureSelected = natureOfBusinessGroup.filter((item) => String(item.keyword) === String(value));

        // if (natureSelected && natureSelected.length > 0) {
        //     const cusGroup = natureSelected[0].customerGroup ? natureSelected[0].customerGroup : [];
        //     setRegionsGroup([...cusGroup]);
        // }

        setRefreshForm(!refreshForm);
    };

    const chooseSpecialty = (value) => {
        formCustomerData.specialty = value;
        setRefreshForm(!refreshForm);
    };

    const chooseRegionGroup = (value) => {
        formCustomerData.regionGroup = value;
        setRefreshForm(!refreshForm);
    };

    const chooseDesignationGroup = (value) => {
        formCustomerData.designation = value;
        // Require license file
        setIsRequireAPCLicense(false);
        setIsRequirePoisonLicense(false);

        switch (value) {
            case DesignationRequireLicenseFile.DOCTOR:
                setIsRequireAPCLicense(true);
                break;

            case DesignationRequireLicenseFile.PHARMACIST:
                setIsRequireAPCLicense(true);
                setIsRequirePoisonLicense(true);
                break;

            default:
                break;
        }

        setRefreshForm(!refreshForm);
    };

    // Billing info
    const chooseBillingState = (value) => {
        formBilling.locCodes.stateCode = value;
        formBilling.state = '';

        const stateSelected = findStateByCode(billingStates, value);

        if (stateSelected) {
            formBilling.state = stateSelected.NameState;
        }

        setRefreshForm(!refreshForm);
    };

    // Billing info
    const chooseBillingCity = (value) => {
        setBillingDistricts([]);
        setBillingWards([]);

        if (formBilling.locCodes == null) {
            formBilling.locCodes = new LocCodesModel();
            formBilling.locCodes.countryCode = formProfile.locCodes ? formProfile.locCodes.countryCode : '';
        }

        formBilling.locCodes.cityCode = value;
        formBilling.locCodes.districtCode = '';
        formBilling.locCodes.wardCode = '';
        formBilling.city = '';
        formBilling.district = '';
        formBilling.ward = '';

        if (value) {
            const result = filterDistricts(locationData, value);
            const citySelected = findCityByCode(billingCities, value);

            if (citySelected) {
                formBilling.city = citySelected.NameCity;
            }

            setBillingDistricts(result);
        }
    };

    const chooseBillingDistrict = (value) => {
        setBillingWards([]);
        formBilling.locCodes.districtCode = value;
        formBilling.locCodes.wardCode = '';
        formBilling.district = '';
        formBilling.ward = '';

        if (value) {
            const result = filterWards(locationData, value);
            const districtSelected = findDistrictByCode(billingDistricts, value);;

            if (districtSelected) {
                formBilling.district = districtSelected.NameDistrict;
            }

            setBillingWards(result);
        }
    };

    const chooseBillingWard = (value) => {
        formBilling.locCodes.wardCode = value;
        formBilling.ward = '';
        const wardSelected = findWardByCode(billingWards, value);

        if (wardSelected) {
            formBilling.ward = wardSelected.NameWard;
            setBillingWards([...billingWards]);
        }
        setRefreshForm(!refreshForm);
    };

    // Shipping info
    const chooseShippingState = (value) => {
        formShipping.locCodes.stateCode = value;
        formShipping.state = '';

        const stateSelected = findStateByCode(shippingStates, value);

        if (stateSelected) {
            formShipping.state = stateSelected.NameState;
        }

        setRefreshForm(!refreshForm);
    };

    // Shipping info
    const chooseShippingCity = (value) => {
        formShipping.locCodes.cityCode = value;
        formShipping.locCodes.districtCode = '';
        formShipping.locCodes.wardCode = '';
        formShipping.city = '';
        formShipping.district = '';
        formShipping.ward = '';
        setShippingDistricts([]);
        setShippingWards([]);

        if (value) {
            const result = filterDistricts(locationData, value);
            const citySelected = findCityByCode(shippingCities, value);

            if (citySelected) {
                formShipping.city = citySelected.NameCity;
            }

            setShippingDistricts(result);
        }
    };

    const chooseShippingDistrict = (value) => {
        setShippingWards([]);
        formShipping.locCodes.districtCode = value;
        formShipping.locCodes.wardCode = '';
        formShipping.district = '';
        formShipping.ward = '';

        if (value) {
            const result = filterWards(locationData, value);
            const districtSelected = findDistrictByCode(shippingDistricts, value);

            if (districtSelected) {
                formShipping.district = districtSelected.NameDistrict;
            }

            setShippingWards(result);
        }
    };

    const chooseShippingWard = (value) => {
        formShipping.locCodes.wardCode = value;
        formShipping.ward = '';
        const wardSelected = findWardByCode(shippingWards, value);

        if (wardSelected) {
            formShipping.ward = wardSelected.NameWard;
            setShippingWards([...shippingWards]);
        }

        setRefreshForm(!refreshForm);
    };

    const chooseBusinessLicensePlaceOfIssue = (value) => {
        formProfile.locCodes.businessLicensePOICode = value;
        const citySelected = findCityByCode(cities, value);

        if (citySelected) {
            formProfile.businessLicensePlaceOfIssue = citySelected.NameCity;
            setCities([...cities]);
        }

        setRefreshForm(!refreshForm);
    };

    const choosePharmaBusinessLicensePlaceOfIssue = (value) => {
        formProfile.locCodes.pharmaBusinessLicensePOICode = value;
        const citySelected = findCityByCode(cities, value);

        if (citySelected) {
            formProfile.pharmaBusinessLicensePlaceOfIssue = citySelected.NameCity;
            setCities([...cities]);
        }

        setRefreshForm(!refreshForm);
    };

    const onChangeDate = e => {
        // const newDate = moment(new Date(e.target.value)).format('YYYY-MM-DD');
        const fieldName = e.target.name;

        setFormProfile((prevState) => ({
            ...prevState,
            [fieldName]: e.target.value
        }));
    };

    const onHandleDeleteRefId = (index) => {
        if (refIds.length > index) {
            if (refIds[index].vendorId === SpecialVendors.VENDOR_DEFAULT) {
                // Send request remove the VietPOM identity
                removeVietPOMIdentity(index);
            } else {
                refIds.splice(index, 1);
                setRefIds([...refIds]);
            }
        }
    };

    const onUpdateRefId = (index, data) => {
        if (refIds.length > index) {
            refIds[index] = data;

            setRefIds([...refIds]);
        }
    };

    const removeVietPOMIdentity = async (index) => {
        if (refIds.length > index) {
            try {
                setLoading(true);
                await CustomersService.removeVietPOMIdentity(editUnifiedCustomer.id, refIds[index].refId)
                    .then((response) => {
                        setLoading(false);

                        if (response) {
                            editUnifiedCustomer.pomId = '';
                            refIds.splice(index, 1);
                            setRefIds([...refIds]);
                        } else {
                            showToastError(TextApp.getText(TextKey.delete_failed));
                        }
                    });
            } catch (error) {
                setLoading(false);

                if (error && error.message) {
                    showToastError(error.message);
                } else {
                    showToastError(TextApp.getText(TextKey.delete_failed));
                }
            }
        }
    };

    const onFindCustomer = async (index, customerCode, phoneNumber) => {
        if (refIds.length > index) {
            try {
                setLoading(true);
                // Find customer code from vendor
                await CustomersService.findCustomerCodeFromVendor(refIds[index].vendor, { customerCode, phoneNumber })
                    .then((response) => {
                        setLoading(false);

                        if (response) {
                            updateRefId(index, response);
                        } else {
                            showToast(TextApp.getText(TextKey.not_found));
                        }
                    });
            } catch (error) {
                setLoading(false);
                showToast(TextApp.getText(TextKey.not_found));
                // if (error && error.message) {
                //     showToastError(error.message);
                // }
            }
        }
    };

    const updateRefId = (index, refId) => {
        if (refIds.length > index) {
            let isExist = false;

            for (let i = 0; i < refIds.length; i++) {
                if (refIds[i].refId === refId && refIds[i].vendorId === refIds[index].vendorId) {
                    isExist = true;
                    break;
                }
            }

            if (!isExist) {
                refIds[index].refId = refId;
                setRefIds([...refIds]);
            } else {
                refIds.splice(index, 1);
                setRefIds([...refIds]);
            }
        }
    };

    const isEnableAddPOMIdentity = (vendor) => {
        // return editUnifiedCustomer && editUnifiedCustomer.verification === VerificationStatus.APPROVED &&
        //     (editUnifiedCustomer.pomId == null || editUnifiedCustomer.pomId === '' || !editUnifiedCustomer.isSentVietPOM);
        return false;
    };

    // const isEnableAddVendorIdentity = (vendor) => {
    //     return vendor && vendor.vendorIdentityCallbackEndpoint && vendor.vendorId !== SpecialVendors.VENDOR_DEFAULT;
    // };

    // const onAddIdentity = (vendor) => {
    //     const newRefId = new RefIdModel('', vendor.vendorId);
    //     newRefId['id'] = generateKey();
    //     newRefId['vendor'] = vendor;

    //     setRefIds([...refIds, newRefId]);
    // };

    const onAddVietPOMIdentity = async () => {
        try {
            setLoading(true);
            await CustomersService.addVietPOMIdentity(editUnifiedCustomer.id)
                .then((response) => {
                    setLoading(false);

                    if (response && response.success && response.data) {
                        // if (response.message) {
                        //     showToastSuccess(response.message);
                        // }

                        const newRefId = new RefIdModel(response.data, SpecialVendors.VENDOR_DEFAULT);
                        newRefId['id'] = generateKey();
                        newRefId['vendor'] = getVendorInfoById(vendors, SpecialVendors.VENDOR_DEFAULT);

                        setRefIds([...refIds, newRefId]);
                    } else {
                        showToastError(`Failed`);
                    }
                });
        } catch (error) {
            setLoading(false);

            if (error && error.message) {
                showToastError(error.message);
            }
        }
    };

    // const chooseVerification = (value) => {
    //     setVerification(value);

    //     if (value !== VerificationStatus.APPROVED) {
    //         setStatus(UserStatus.DISABLED);
    //     } else {
    //         setStatus('');
    //     }
    // };

    const chooseAccessLevel = (value) => {
        setRefreshForm(!refreshForm);
    };

    const openOriginalFile = (fileURL) => {
        if (fileURL == null || fileURL === '') {
            showToastWarning('File is empty');
        } else {
            getSignedFileURL(fileURL);
            // setDisplayFileURL(fileURL);
        }
    }

    const getBusinessLicenseFileURL = (cusData) => {
        let fileURL = '';

        if (cusData) {
            fileURL = cusData.profileInfo && cusData.profileInfo.businessLicenseURL ? cusData.profileInfo.businessLicenseURL : '';

            if (cusData.keyFile && cusData.keyFile.businessLicenseKeyFile) {
                fileURL = AssetsService.getFileURL(cusData.keyFile.businessLicenseKeyFile);
            }
        }

        return fileURL;
    };

    const getPharmaBusinessLicenseFileURL = (cusData) => {
        let fileURL = '';

        if (cusData) {
            fileURL = cusData.profileInfo && cusData.profileInfo.pharmaBusinessLicenseURL ? cusData.profileInfo.pharmaBusinessLicenseURL : '';

            if (cusData.keyFile && cusData.keyFile.pharmaBusinessLicenseKeyFile) {
                fileURL = AssetsService.getFileURL(cusData.keyFile.pharmaBusinessLicenseKeyFile);
            }
        }

        return fileURL;
    };

    const getApcFileURL = (cusData) => {
        let fileURL = '';

        if (cusData) {
            fileURL = cusData.profileInfo && cusData.profileInfo.apcLicenseURL ? cusData.profileInfo.apcLicenseURL : '';

            if (cusData.keyFile && cusData.keyFile.apcLicenseKeyFile) {
                fileURL = AssetsService.getFileURL(cusData.keyFile.apcLicenseKeyFile);
            }
        }

        return fileURL;
    };

    const getPoisonALicenseFileURL = (cusData) => {
        let fileURL = '';

        if (cusData) {
            fileURL = cusData.profileInfo && cusData.profileInfo.poisonALicenseURL ? cusData.profileInfo.poisonALicenseURL : '';

            if (cusData.keyFile && cusData.keyFile.poisonALicenseKeyFile) {
                fileURL = AssetsService.getFileURL(cusData.keyFile.poisonALicenseKeyFile);
            }
        }

        return fileURL;
    };

    const getVendorInfoById = (allVendors, vendorId) => {
        let result = null;

        if (allVendors && allVendors.length) {
            for (let i = 0; i < allVendors.length; i++) {
                if (allVendors[i].vendorId === vendorId) {
                    result = allVendors[i];
                    break;
                }
            }
        }

        return result;
    };

    const getPhoneNumberFormatHint = () => {
        const countryCode = formProfile && formProfile.locCodes ? formProfile.locCodes.countryCode : CountryCodes.VIETNAM_COUNTRY_CODE;
        return makeMobileNumberFormatHint(countryCode);
    };

    const getSignedFileURL = async (url) => {
        try {
            const signedResult = url ? await AssetsService.getSignedURL(url, true) : null;

            if (signedResult) {
                if (signedResult.ext && DOCS_FILE_EXTENSION.indexOf(signedResult.ext) >= 0) {
                    var a = document.createElement('a');
                    a.href = AssetsService.getDocumentFileURL(signedResult.keyFile);
                    a.target = '_blank';
                    a.click();
                } else {
                    setDisplayFileURL(signedResult.url);
                }
            } else {
                setDisplayFileURL('');
            }
        } catch (error) {
            console.log(error);
        }
    };

    const loadData = async () => {
        const params = new URLSearchParams(location.search)
        const customerId = params && params.get(QueryParamsKey.CUSTOMER_ID) ? params.get(QueryParamsKey.CUSTOMER_ID) : '';
        // Load all states in MY
        loadStates();
        // Load all cities in Viet Nam
        // loadCities();
        // Load all vendors available
        try {
            const allVendors = isAdmin ? await VendorsService.findVendors({}, false, true) : [];
            setVendors(allVendors);

            // Fetch unified customer details
            if (customerId) {
                getUnifiedCustomerDetails(allVendors, customerId);
            }
        } catch (error) {
            // console.warn(error);
        }
    };

    /**
     * Load all sub-users
     */
    const loadSubUsers = async () => {
        const params = new URLSearchParams(location.search)
        const customerId = params && params.get(QueryParamsKey.CUSTOMER_ID) ? params.get(QueryParamsKey.CUSTOMER_ID) : '';

        try {
            const allSubusers = isAdmin ? await CustomersService.getSubUsers(customerId) : [];
            setSubusers(allSubusers);
        } catch (error) {
            // console.warn(error);
        }
    };

    useEffect(() => {
        if (initialized.current)
            return;

        initialized.current = true;
        // Define all tabs customer info
        if (isAdmin) {
            let allTabs = [];
            allTabs = allTabs.concat(AllTabsCustomerInfo);
            allTabs = allTabs.concat(TabsCustomerAccounts);

            setTabsCustomerInfo([...allTabs]);
        } else {
            setTabsCustomerInfo(AllTabsCustomerForVendor);
        }
        // Default country / region
        // chooseProfileCountry(CountryCodes.VIETNAM_COUNTRY_CODE);
        // chooseShippingCountry(CountryCodes.VIETNAM_COUNTRY_CODE);
        // Load data
        loadData();
        // Load all sub-users
        loadSubUsers();
    }, [navigate, location]);

    /**
     * Get unified customer details
     * @param {*} customerId 
     */
    const getUnifiedCustomerDetails = async (allVendors, customerId) => {
        try {
            setLoading(true);
            await CustomersService.getUnifiedCustomerDetails(customerId)
                .then((response) => {
                    setLoading(false);

                    if (response) {
                        // Customer details: Always display new information
                        const uc = response;
                        let details = response.details;

                        if (response.verifyInfo && response.verifyInfo !== '') {
                            // Old info
                            setOldUCInfo(response.details);
                            details = response.verifyInfo;
                            uc.details = response.verifyInfo;
                            // New Subusers
                            if (response.verifyInfo.subUsers) {
                                setNewSubusersKYC(response.verifyInfo.subUsers);
                            }
                        } else {
                            setOldUCInfo(null);
                        }

                        // Refresh info on form
                        setEditUnifiedCustomer(uc);
                        setNote(uc.note != null ? uc.note : '');

                        if (details) {
                            setVerification(response.verification);
                            // Remove temporary field from server
                            details.rawData = null;
                            setFormCustomerData(details);
                            // Business info
                            chooseNatureOfBusiness(details.natureOfBusiness ? details.natureOfBusiness : '');
                            chooseDesignationGroup(details.designation ? details.designation : '');

                            // Profile info
                            if (details.profileInfo) {
                                setFormProfile(details.profileInfo);
                                // Binding location
                                if (details.profileInfo.locCodes && (details.profileInfo.locCodes.countryCode === '' || details.profileInfo.locCodes.countryCode === CountryCodes.VIETNAM_COUNTRY_CODE)) {
                                    chooseProfileCity(details.profileInfo.locCodes.cityCode);
                                    chooseProfileDistrict(details.profileInfo.locCodes.districtCode);
                                    chooseProfileWard(details.profileInfo.locCodes.wardCode);
                                }
                            }
                            // Shipping info
                            if (details.shippingInfo) {
                                setFormShipping(details.shippingInfo);
                                // Binding location
                                if (details.shippingInfo.locCodes && (details.shippingInfo.locCodes.countryCode === '' || details.shippingInfo.locCodes.countryCode === CountryCodes.VIETNAM_COUNTRY_CODE)) {
                                    chooseShippingCity(details.shippingInfo.locCodes.cityCode);
                                    chooseShippingDistrict(details.shippingInfo.locCodes.districtCode);
                                    chooseShippingWard(details.shippingInfo.locCodes.wardCode);
                                }
                            }
                            // Billing info
                            if (details.billingInfo) {
                                setFormBilling(details.billingInfo);
                                // Binding location
                                if (details.billingInfo.locCodes && (details.profileInfo.locCodes && (details.profileInfo.locCodes.countryCode === '' || details.profileInfo.locCodes.countryCode === CountryCodes.VIETNAM_COUNTRY_CODE))) {
                                    chooseBillingCity(details.billingInfo.locCodes.cityCode);
                                    chooseBillingDistrict(details.billingInfo.locCodes.districtCode);
                                    chooseBillingWard(details.billingInfo.locCodes.wardCode);
                                }
                            }
                            // Ref Id
                            if (response.referenceCodes && response.referenceCodes.length > 0 && allVendors) {
                                let items = [];

                                for (let i = 0; i < response.referenceCodes.length; i++) {
                                    let item = new RefIdModel(response.referenceCodes[i].refId, response.referenceCodes[i].vendorId);

                                    item['id'] = generateKey(Math.random());
                                    item['vendor'] = getVendorInfoById(allVendors, item.vendorId);
                                    items.push(item);
                                }

                                setRefIds([...items]);
                            }
                        }
                    } else {
                        if (response && response.message) {
                            showToastError(response.message);
                        }
                    }
                });
        } catch (error) {
            setLoading(false);

            if (error && error.message) {
                showToastError(error.message);
            }
        }
    };

    /**
     * Handle reject changes
     * @param {*} e 
     * @returns 
     */
    const handleRejectChanges = async (e) => {
        e.preventDefault();

        try {
            setLoading(true);

            await CustomersService.rejectAllChangesCustomerInfo(editUnifiedCustomer.id, note)
                .then((response) => {
                    setLoading(false);

                    if (response) {
                        if (response.message) {
                            showToastSuccess(response.message);
                        }

                        resetForm();
                        navigate('/customers', { replace: true });
                    }
                });
        } catch (error) {
            setLoading(false);

            if (error && error.message) {
                showToastError(error.message);
            }
        }
    };

    /**
     * Handle submit all information to UCD
     * @param {*} e 
     * @returns 
     */
    const handleSubmitInfo = (e) => {
        e.preventDefault();

        // Validate data
        if (!validateData()) {
            showToastWarning(TextApp.getText(TextKey.requied_fields));
            return;
        }

        let unifiedCustomer = new UnifiedCustomerModel();
        let customerInfo = new CustomerInfoModel(userLogged && userLogged.vendorId ? userLogged.vendorId : VendorIdDefault);
        // Form data
        const data = new FormData();

        // if (businessLicenseFile == null) {
        //     showToastError(TextApp.getText(TextKey.provide_business_license_certificate));
        //     return;
        // } else if (isRequireAPCLicense && apcLicenseFile == null) {
        //     showToastError(TextApp.getText(TextKey.provide_apc_license_certificate));
        //     return;
        // } else if (isRequirePoisonLicense && poisonALicenseFile == null) {
        //     showToastError(TextApp.getText(TextKey.provide_poison_a_license_certificate));
        //     return;
        // }

        customerInfo = formCustomerData;
        customerInfo.profileInfo = formProfile;
        customerInfo.billingInfo = formBilling;
        customerInfo.shippingInfo = formShipping;
        // Format phone number: [country_code][phone_number]
        const countryCode = formProfile && formProfile.locCodes ? formProfile.locCodes.countryCode : CountryCodes.MALAYSIA_COUNTRY_CODE;

        if (formCustomerData.phoneNumber) {
            const profilePhoneNumber = reFormatPhoneNumber(formCustomerData.phoneNumber, countryCode);
            customerInfo.phoneNumber = profilePhoneNumber;
        }

        if (formShipping.contactNumber) {
            const shippingContactNumber = reFormatPhoneNumber(formShipping.contactNumber, countryCode);
            customerInfo.shippingInfo.contactNumber = shippingContactNumber;
        }
        // Reference ID
        // unifiedCustomer.referenceCodes = [];

        if (editUnifiedCustomer) {
            unifiedCustomer.id = editUnifiedCustomer.id;
            // Original file
            customerInfo.keyFile = editUnifiedCustomer.details && editUnifiedCustomer.details.keyFile ? editUnifiedCustomer.details.keyFile : null;
        }

        // if (refIds.length > 0) {
        //     for (let i = 0; i < refIds.length; i++) {
        //         if (refIds[i].refId && refIds[i].vendorId) {
        //             unifiedCustomer.referenceCodes.push(new RefIdModel(refIds[i].refId, refIds[i].vendorId));
        //         }
        //     }
        // }

        customerInfo.verification = verification;
        // Unified customer
        // unifiedCustomer.salesRepId = formCustomerData.salesRepId;
        unifiedCustomer.verification = verification;
        // unifiedCustomer.status = status;
        unifiedCustomer.details = customerInfo;
        unifiedCustomer.note = note;
        unifiedCustomer.mappingData();

        // Data submit
        data.append(FormDataRequest.BUSINESS_LICENSE_FILE_KEY, businessLicenseFile);
        data.append(FormDataRequest.APC_LICENSE_FILE_KEY, apcLicenseFile);
        data.append(FormDataRequest.POISON_A_LICENSE_FILE_KEY, poisonALicenseFile);
        data.append(FormDataRequest.DATA_KEY, JSON.stringify(unifiedCustomer));

        // Send data to UCD
        sendDataToUCD(data);
    };

    /**
     * Approve the customer
     * @param {*} e 
     */
    const handleApprove = async (e) => {
        try {
            // Validate data
            if (!validateData()) {
                showToastWarning(TextApp.getText(TextKey.requied_fields));
                return;
            }

            setLoading(true);

            await CustomersService.verifiedCustomer(editUnifiedCustomer.id, VerificationStatus.APPROVED)
                .then((response) => {
                    setLoading(false);

                    if (response) {
                        if (response.message) {
                            showToastSuccess(response.message);
                        } else {
                            showToastSuccess(TextApp.getText(TextKey.update_successful));
                        }
                    }

                    resetForm();
                    // navigate(-1);
                    navigate('/customers', { replace: true });
                });
        } catch (error) {
            setLoading(false);

            if (error && error.message) {
                showToastError(error.message);
            }
        }
    };

    /**
     * Validate form
     * @returns
     */
    const validateData = () => {
        const error = { ...errorObj };
        const resultValidateCustomer = CustomerInfoModel.validate(formCustomerData, error, isAdmin, enableEnterManualProfileAddress);
        // Profile
        const resultValidateProfile = ProfileInfoModel.validate(formProfile, error.profileInfo);
        // Shipping address
        const resultValidateShipping = ShippingInfoModel.validate(formShipping, error.shippingInfo, enableEnterManualProfileAddress);
        // Billing address
        const resultValidateBilling = true; // BillingInfoModel.validate(formBilling, error.billingInfo);
        // Validate phone number and email if needed
        let validatePhoneAndEmail = true;
        const countryCode = formProfile && formProfile.locCodes ? formProfile.locCodes.countryCode : CountryCodes.MALAYSIA_COUNTRY_CODE;

        // Profile
        if (formCustomerData.phoneNumber != null && formCustomerData.phoneNumber !== '') {
            if (!validatePhoneNumber(formCustomerData.phoneNumber, countryCode, countryCode === CountryCodes.VIETNAM_COUNTRY_CODE)) {
                validatePhoneAndEmail = false;
                error.phoneNumber = TextApp.getText(TextKey.phone_number_invalid);
            }
        }
        // Shipping
        if (formShipping.contactNumber != null && formShipping.contactNumber !== '') {
            if (!validatePhoneNumber(formShipping.contactNumber, countryCode, countryCode === CountryCodes.VIETNAM_COUNTRY_CODE)) {
                validatePhoneAndEmail = false;
                error.shippingInfo.contactNumber = TextApp.getText(TextKey.phone_number_invalid);
            }
        }
        // Email
        if (formCustomerData.email != null && formCustomerData.email !== '') {
            if (!validateEmail(formCustomerData.email)) {
                validatePhoneAndEmail = false;
                error.email = TextApp.getText(TextKey.email_invalid);
            }
        }

        setErrors(error);

        return resultValidateCustomer && resultValidateProfile && resultValidateShipping && resultValidateBilling && validatePhoneAndEmail;
    }

    /**
    * Send customer info to vietpom ucd
    * @param {} customerInfo 
    */
    const sendDataToUCD = async (customerInfo) => {
        try {
            setLoading(true);

            await CustomersService.updateCustomer(editUnifiedCustomer.id, customerInfo)
                .then((response) => {
                    setLoading(false);

                    if (response && response.success) {
                        showToastSuccess(TextApp.getText(TextKey.update_successful));
                        resetForm();
                        // navigate(-1);
                        navigate('/customers', { replace: true });
                    } else {
                        if (response && response.message) {
                            showToastError(response.message);
                        }
                    }
                });
        } catch (error) {
            setLoading(false);

            if (error && error.message) {
                showToastError(error.message);
            }
        }
    };

    const resetForm = () => {
        let customerInfo = new CustomerInfoModel(userLogged && userLogged.vendorId ? userLogged.vendorId : VendorIdDefault);

        setFormCustomerData(customerInfo);
        setFormProfile(customerInfo.profileInfo);
        setFormBilling(customerInfo.billingInfo);
        setFormShipping(customerInfo.shippingInfo);
        setEditUnifiedCustomer(null);
        setOldUCInfo(null);
    };

    const isErrorOnSubUser = (item) => {
        return item && !item.isSentVietPOM;
    };

    const getErrorOnSubUser = (item) => {
        let errorMessage = TextApp.getText(TextKey.failing_sending_data_to_vietpom);
        let errorMessageFromVietPOM = '';

        // Error from POMacy
        if (item && item.vietpomResponse && item.vietpomResponse.response.messages && item.vietpomResponse.response.messages.length > 0) {
            for (let i = 0; i < item.vietpomResponse.response.messages.length; i++) {
                errorMessageFromVietPOM += item.vietpomResponse.response.messages[i] + ' ';
            }
        }

        return errorMessageFromVietPOM !== '' ? `${errorMessage} [ ${errorMessageFromVietPOM}]` : errorMessage;
    };

    /**
    * Add/Edit sub-user
    */
    function displayDetailsSubUser(subAccount = null) {
        let subAcc = new SubUserModel();
        const countryCode = formProfile && formProfile.locCodes && formProfile.locCodes.countryCode ? formProfile.locCodes.countryCode : CountryCodes.VIETNAM_COUNTRY_CODE;

        if (subAccount) {
            subAcc = subAccount;
        }

        if (editUnifiedCustomer) {
            subAcc.pomId = editUnifiedCustomer.pomId;
            subAcc.customerNo = editUnifiedCustomer.ucdCode;
        }

        subuserRef.current.showDetailsModal(subAcc, countryCode);
    };

    /**
     * Handle save sub-user
     * @param {*} subAccount 
     * @returns 
     */
    const handleSaveSubUser = async (subAccount) => {
        try {
            if (!subAccount) {
                return false;
            }

            setLoading(true);

            if (subAccount.id) {
                await CustomersService.updateSubUser(editUnifiedCustomer.id, subAccount.id, subAccount)
                    .then((response) => {
                        setLoading(false);

                        if (response && response.message) {
                            showToastSuccess(response.message);
                        }
                    });
            } else {
                await CustomersService.createSubUser(editUnifiedCustomer.id, subAccount)
                    .then((response) => {
                        setLoading(false);

                        if (response && response.message) {
                            showToastSuccess(response.message);
                        }
                    });
            }

            // Refresh list sub-users
            loadSubUsers();
        } catch (error) {
            setLoading(false);
            if (error && error.message) {
                showToastError(error.message);
            }
        }
    };

    /**
     * Option edit sub-user
     * @param {*} account 
     * @param {*} opt 
     */
    const chooseOptionMenuSubUser = (account, opt) => {
        switch (opt) {
            case ActionOnRecord.EDIT:
                displayDetailsSubUser(account);
                break;

            case ActionOnRecord.DELETE:
                showDialogDelete(account);
                break;

            default:
                setLoading(false);
                break;
        }
    }

    /**
     * Popup dialog confirm delete the sub-user
     * @param {*} subAcc
     */
    const showDialogDelete = (subAcc) => {
        const title = `Delete User: ${subAcc.name}`;
        const message = `Are you sure you want to delete this user?`;

        deleteSubUserConfirmation.current.showModal(subAcc, { title: title, message: message });
    }

    /**
     * Delete sub-user
     */
    const handleDeleteSubUser = async (subAcc) => {
        try {
            setLoading(true);
            await CustomersService.deteteSubUserById(editUnifiedCustomer.id, subAcc.id)
                .then((response) => {
                    setLoading(false);

                    if (response && response.message) {
                        showToastSuccess(response.message);
                    }
                });
            // Refresh sub-users
            loadSubUsers();
        } catch (error) {
            setLoading(false);

            if (error && error.message) {
                showToastError(error.message);
            }
        }
    };

    return (
        <>
            <MainPagetitle mainTitle="Customer Management" pageTitle="Info" parentTitle="Home" />
            <div className="container-fluid">
                {/* <ToastContainer /> */}
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body p-3">
                            <div className="d-flex align-items-center justify-content-between mb-4">
                                <h4 className="heading mb-0">{editUnifiedCustomer && editUnifiedCustomer.requestVerifyInfoId && editUnifiedCustomer.requestVerifyInfoId !== '' ? <>Edit Customer <span className='text-warning fs-13'>(Verify customer information)</span></> : 'Edit Customer'}</h4>
                                {/* <div className="d-flex align-items-center">
                                    <Link to={"#"} className="btn btn-primary btn-sm ms-2" data-bs-toggle="offcanvas" onClick={() => { }}>
                                        + Import Customer
                                    </Link>
                                </div> */}
                            </div>
                            <div className="dataTables_wrapper no-footer">
                                <Row>
                                    <Col xl={12}>
                                        <Tab.Container defaultActiveKey="Preview">
                                            <Card name="default-tab" className="dz-card">
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="Preview">
                                                        <Card.Body>
                                                            {/* <!-- Nav tabs --> */}
                                                            <div className="default-tab">
                                                                <Tab.Container defaultActiveKey={tabsCustomerInfo.length > 0 ? tabsCustomerInfo[0].key : ''}>
                                                                    <Nav as="ul" className="nav-tabs">
                                                                        {tabsCustomerInfo.map((data, i) => (
                                                                            <Nav.Item as="li" key={i}>
                                                                                <Nav.Link eventKey={data.key}>
                                                                                    <i className={`la la-${data.icon} me-2`} />
                                                                                    {data.name}
                                                                                </Nav.Link>
                                                                            </Nav.Item>
                                                                        ))}
                                                                    </Nav>
                                                                    <Tab.Content className="pt-4">
                                                                        {tabsCustomerInfo.map((data, i) => (
                                                                            <Tab.Pane eventKey={data.key} key={i}>
                                                                                {/* Basic Information */}
                                                                                {data.key === CustomerTabKeys.BASIC_INFORMATION ? <>
                                                                                    <form id="#basicInfoForm">
                                                                                        <div className="form-validation">
                                                                                            <div className="row">
                                                                                                <div className="col-xl-6">
                                                                                                    {editUnifiedCustomer && editUnifiedCustomer.pomId && <>
                                                                                                        <div className="form-group mb-3 row">
                                                                                                            <label className="col-lg-4 col-form-label text-success" htmlFor="val-ucd-code">POM ID</label>
                                                                                                            <div className="col-lg-7">
                                                                                                                <input type="text" className="form-control bg-disabled" id="val-ucd-code" name="val-ucd-code" placeholder="Auto generate after approved" value={editUnifiedCustomer && editUnifiedCustomer.pomId ? editUnifiedCustomer.pomId : 'Auto generate from POMacy'} disabled />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </>}
                                                                                                    <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label text-success" htmlFor="val-ucd-code">UCD Code</label>
                                                                                                        <div className="col-lg-7">
                                                                                                            <input type="text" className="form-control bg-disabled" id="val-ucd-code" name="val-ucd-code" placeholder="Auto generate after approved" value={editUnifiedCustomer && editUnifiedCustomer.ucdCode ? editUnifiedCustomer.ucdCode : ''} disabled />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {/* <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="val-ucd-code">Sales Rep ID</label>
                                                                                                        <div className="col-lg-7">
                                                                                                            {oldUCInfo && hasDifferent(formCustomerData.salesRepId, oldUCInfo.salesRepId) && <div className="text-warning fs-12">{oldUCInfo.salesRepId ? oldUCInfo.salesRepId : '-'}</div>}
                                                                                                            <input type="text" className="form-control" id="sales-rep-id" name="salesRepId" value={formCustomerData.salesRepId ? formCustomerData.salesRepId : ''} onChange={handleInputFormInfo} />
                                                                                                        </div>
                                                                                                    </div> */}
                                                                                                    {!isAdmin && <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="val-customer-code">Customer Code<span className="text-danger">*</span></label>
                                                                                                        <div className="col-lg-7">
                                                                                                            <input type="text" className="form-control" id="val-customer-code" name="customerCode" value={formCustomerData.customerCode ? formCustomerData.customerCode : ''} onChange={handleInputFormInfoNoSpace} placeholder="Enter customer code" required />
                                                                                                            {errors.customerCode && <div className="text-danger fs-12">{errors.customerCode}</div>}
                                                                                                        </div>
                                                                                                    </div>}
                                                                                                    <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="val-prefix">Prefix</label>
                                                                                                        <div className="col-lg-7">
                                                                                                            <select className="form-control" id="val-prefix" name="val-prefix" value={formCustomerData.prefix} onChange={(e) => choosePrefix(e.target.value)}>
                                                                                                                <option value="">Please select</option>
                                                                                                                {prefixList.map((group, i) => { return (<option value={group.keyword} key={i}>{group.name}</option>); })}
                                                                                                            </select>
                                                                                                            {errors.prefix && <div className="text-danger fs-12">{errors.prefix}</div>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="val-healthcare-full-name">Healthcare Professional Full Name<span className="text-danger">*</span></label>
                                                                                                        <div className="col-lg-7">
                                                                                                            {oldUCInfo && hasDifferent(formCustomerData.healthcareFullName, oldUCInfo.healthcareFullName) && <div className="text-warning fs-12">{oldUCInfo.healthcareFullName ? oldUCInfo.healthcareFullName : '-'}</div>}
                                                                                                            <input type="text" className="form-control" id="val-healthcare-full-name" name="healthcareFullName" value={formCustomerData.healthcareFullName} onChange={handleInputFormInfo} required />
                                                                                                            {errors.healthcareFullName && <div className="text-danger fs-12">{errors.healthcareFullName}</div>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="representative-name">Business Registration Name<span className="text-danger">*</span></label>
                                                                                                        <div className="col-lg-7">
                                                                                                            {oldUCInfo && hasDifferent(formCustomerData.representativeName, oldUCInfo.representativeName) && <div className="text-warning fs-12">{oldUCInfo.representativeName}</div>}
                                                                                                            <input type="text" className="form-control" id="representative-name" name="representativeName" value={formCustomerData.representativeName} onChange={handleInputFormInfo} required />
                                                                                                            {errors.representativeName && <div className="text-danger fs-12">{errors.representativeName}</div>}
                                                                                                            {(formCustomerData.firstName != null && formCustomerData.firstName !== '') || (formCustomerData.lastName != null && formCustomerData.lastName !== '') ? <>
                                                                                                                <div className="mt-2">
                                                                                                                    <label className="col-form-label" htmlFor="first-name"><i>First Name</i></label>
                                                                                                                    <input type="text" className="form-control" id="first-name" name="firstName" value={formCustomerData.firstName ? formCustomerData.firstName : ''} onChange={handleInputFormInfo} placeholder="" />
                                                                                                                    <label className=" col-form-label" htmlFor="last-name"><i>Last Name</i></label>
                                                                                                                    <input type="text" className="form-control" id="last-name" name="lastName" value={formCustomerData.lastName ? formCustomerData.lastName : ''} onChange={handleInputFormInfo} placeholder="" />
                                                                                                                    {formCustomerData.nameOfPractice ? <>
                                                                                                                        <label className=" col-form-label" htmlFor="name-of-practice"><i>Name of Practice</i></label>
                                                                                                                        <input type="text" className="form-control" id="name-of-practice" name="nameOfPractice" value={formCustomerData.nameOfPractice ? formCustomerData.nameOfPractice : ''} onChange={handleInputFormInfo} placeholder="" /></> : <></>}
                                                                                                                </div>
                                                                                                            </> : <></>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="val-company-name">Company Name<span className="text-danger">*</span></label>
                                                                                                        <div className="col-lg-7">
                                                                                                            {oldUCInfo && hasDifferent(formProfile.companyName, oldUCInfo.profileInfo.companyName) && <div className="text-warning fs-12">{oldUCInfo.profileInfo.companyName}</div>}
                                                                                                            <input type="text" className="form-control" id="val-company-name" name="companyName" value={formProfile.companyName} onChange={handleInputFormProfile} placeholder="" required />
                                                                                                            {errors.profileInfo.companyName && <div className="text-danger fs-12">{errors.profileInfo.companyName}</div>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="val-nature-of-business-group">Specialty<span className="text-danger">*</span></label>
                                                                                                        <div className="col-lg-7">
                                                                                                            <select className="form-control" id="val-nature-of-business-group" name="val-nature-of-business-group" value={formCustomerData.specialty} onChange={(e) => chooseSpecialty(e.target.value)} required>
                                                                                                                <option value="">Please select</option>
                                                                                                                {specialtyList.map((group, i) => { return (<option value={group.keyword} key={i}>{group.name}</option>); })}
                                                                                                            </select>
                                                                                                            {errors.specialty && <div className="text-danger fs-12">{errors.specialty}</div>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="val-nature-of-business-group">Nature of Business<span className="text-danger">*</span></label>
                                                                                                        <div className="col-lg-7">
                                                                                                            {oldUCInfo && hasDifferent(formCustomerData.natureOfBusiness, oldUCInfo.natureOfBusiness) && <div className="text-warning fs-12">{NatureOfBusinessDefault.GetName(oldUCInfo.natureOfBusiness, natureOfBusinessGroup)}</div>}
                                                                                                            <select className="form-control" id="val-nature-of-business-group" name="val-nature-of-business-group" value={formCustomerData.natureOfBusiness} onChange={(e) => chooseNatureOfBusiness(e.target.value)} required>
                                                                                                                <option value="">Please select</option>
                                                                                                                {natureOfBusinessGroup.map((group, i) => { return (<option value={group.keyword} key={i}>{group.name}</option>); })}
                                                                                                            </select>
                                                                                                            {errors.natureOfBusiness && <div className="text-danger fs-12">{errors.natureOfBusiness}</div>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {/* <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="val-region-group">Customer Group<span className="text-danger">*</span></label>
                                                                                                        <div className="col-lg-7">
                                                                                                            {oldUCInfo && hasDifferent(formCustomerData.regionGroup, oldUCInfo.regionGroup) && <div className="text-warning fs-12">{NatureOfBusinessDefault.GetName(oldUCInfo.regionGroup, regionsGroup)}</div>}
                                                                                                            <select className="form-control" id="val-region-group" name="val-region-group" value={formCustomerData.regionGroup} onChange={(e) => chooseRegionGroup(e.target.value)} required>
                                                                                                                <option value="">Please select</option>
                                                                                                                {regionsGroup.map((group, i) => { return (<option value={group.keyword} key={i}>{group.name}</option>); })}
                                                                                                            </select>
                                                                                                            {errors.regionGroup && <div className="text-danger fs-12">{errors.regionGroup}</div>}
                                                                                                        </div>
                                                                                                    </div> */}
                                                                                                    <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="val-designation-group">Designation<span className="text-danger">*</span></label>
                                                                                                        <div className="col-lg-7">
                                                                                                            {oldUCInfo && hasDifferent(formCustomerData.designation, oldUCInfo.designation) && <div className="text-warning fs-12">{RoleOfSubUserDefault.GetName(oldUCInfo.designation)}</div>}
                                                                                                            <select className="form-control" id="val-designation-group" name="val-designation-group" value={formCustomerData.designation} onChange={(e) => chooseDesignationGroup(e.target.value)} required>
                                                                                                                <option value="">Please select</option>
                                                                                                                {RoleOfSubUserDefault.ListAvailable().map((group, i) => { return (<option value={group.keyword} key={i}>{group.name}</option>); })}
                                                                                                            </select>
                                                                                                            {errors.designation && <div className="text-danger fs-12">{errors.designation}</div>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="val-user-group">User Group</label>
                                                                                                        <div className="col-lg-7">
                                                                                                            {oldUCInfo && hasDifferent(formCustomerData.userGroup, oldUCInfo.userGroup) && <div className="text-warning fs-12">{oldUCInfo.userGroup}</div>}
                                                                                                            <input type="text" className="form-control" id="val-user-group" name="userGroup" value={formCustomerData.userGroup} onChange={handleInputFormInfo} placeholder="" />
                                                                                                            {/* <select className="form-control" id="val-user-group" name="val-user-group" value={formCustomerData.userGroup} onChange={(e) => chooseForensicGroup(e.target.value)}>
                                                                                                                <option value="">Please select</option>
                                                                                                                {userGroup.map((group, i) => { return (<option value={group.keyword} key={i}>{group.name}</option>); })}
                                                                                                            </select> */}
                                                                                                            {errors.userGroup && <div className="text-danger fs-12">{errors.userGroup}</div>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                {/* Business license files */}
                                                                                                <div className="col-xl-6">
                                                                                                    <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="val-mmc-no">MMC Number<span className="text-danger">*</span></label>
                                                                                                        <div className="col-lg-7">
                                                                                                            {oldUCInfo && hasDifferent(formProfile.mmcNo, oldUCInfo.profileInfo.mmcNo) && <div className="text-warning fs-12">{oldUCInfo.profileInfo.mmcNo ? oldUCInfo.profileInfo.mmcNo : '-'}</div>}
                                                                                                            <input type="text" className="form-control" id="val-mmc-no" name="mmcNo" value={formProfile.mmcNo} onChange={handleInputFormProfileNoSpace} placeholder="" required />
                                                                                                            {errors.profileInfo.mmcNo && <div className="text-danger fs-12">{errors.profileInfo.mmcNo}</div>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="val-ssm-no">SSM Number<span className="text-danger">*</span></label>
                                                                                                        <div className="col-lg-7">
                                                                                                            {oldUCInfo && hasDifferent(formProfile.ssmNo, oldUCInfo.profileInfo.ssmNo) && <div className="text-warning fs-12">{oldUCInfo.profileInfo.ssmNo ? oldUCInfo.profileInfo.ssmNo : '-'}</div>}
                                                                                                            <input type="text" className="form-control" id="val-ssm-no" name="ssmNo" value={formProfile.ssmNo} onChange={handleInputFormProfileNoSpace} placeholder="" required />
                                                                                                            {errors.profileInfo.ssmNo && <div className="text-danger fs-12">{errors.profileInfo.ssmNo}</div>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="val-tin-no">TIN Number<span className="text-danger">*</span></label>
                                                                                                        <div className="col-lg-7">
                                                                                                            {oldUCInfo && hasDifferent(formProfile.tinNo, oldUCInfo.profileInfo.tinNo) && <div className="text-warning fs-12">{oldUCInfo.profileInfo.tinNo ? oldUCInfo.profileInfo.tinNo : '-'}</div>}
                                                                                                            <input type="text" className="form-control" id="val-tin-no" name="tinNo" value={formProfile.tinNo} onChange={handleInputFormProfileNoSpace} placeholder="" required />
                                                                                                            {errors.profileInfo.tinNo && <div className="text-danger fs-12">{errors.profileInfo.tinNo}</div>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="val-msic-no">MSIC Code<span className="text-danger">*</span></label>
                                                                                                        <div className="col-lg-7">
                                                                                                            {oldUCInfo && hasDifferent(formProfile.msicNo, oldUCInfo.profileInfo.msicNo) && <div className="text-warning fs-12">{oldUCInfo.profileInfo.msicNo ? oldUCInfo.profileInfo.msicNo : '-'}</div>}
                                                                                                            <input type="text" className="form-control" id="val-msic-no" name="msicNo" value={formProfile.msicNo} onChange={handleInputFormProfileNoSpace} placeholder="" required />
                                                                                                            {errors.profileInfo.msicNo && <div className="text-danger fs-12">{errors.profileInfo.msicNo}</div>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="val-soldToCustomerCode">Sold to Customer Code<span className="text-danger">*</span></label>
                                                                                                        <div className="col-lg-7">
                                                                                                            {oldUCInfo && hasDifferent(formProfile.soldToCustomerCode, oldUCInfo.profileInfo.soldToCustomerCode) && <div className="text-warning fs-12">{oldUCInfo.profileInfo.soldToCustomerCode ? oldUCInfo.profileInfo.soldToCustomerCode : '-'}</div>}
                                                                                                            <input type="text" className="form-control" id="val-soldToCustomerCode" name="soldToCustomerCode" value={formProfile.soldToCustomerCode} onChange={handleInputFormProfileNoSpace} placeholder="" required />
                                                                                                            {errors.profileInfo.soldToCustomerCode && <div className="text-danger fs-12">{errors.profileInfo.soldToCustomerCode}</div>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="val-shipToCustomerCode">Ship to Customer Code<span className="text-danger">*</span></label>
                                                                                                        <div className="col-lg-7">
                                                                                                            {oldUCInfo && hasDifferent(formProfile.shipToCustomerCode, oldUCInfo.profileInfo.shipToCustomerCode) && <div className="text-warning fs-12">{oldUCInfo.profileInfo.shipToCustomerCode ? oldUCInfo.profileInfo.shipToCustomerCode : '-'}</div>}
                                                                                                            <input type="text" className="form-control" id="val-shipToCustomerCode" name="shipToCustomerCode" value={formProfile.shipToCustomerCode} onChange={handleInputFormProfileNoSpace} placeholder="" required />
                                                                                                            {errors.profileInfo.shipToCustomerCode && <div className="text-danger fs-12">{errors.profileInfo.shipToCustomerCode}</div>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="val-business-license-no">SSM Form 9 / Form B<span className="text-danger">*</span></label>
                                                                                                        <div className="col-lg-7">
                                                                                                            {/* <div className='mb-2'>
                                                                                                                {oldUCInfo && hasDifferent(formProfile.businessLicenseNo, oldUCInfo.profileInfo.businessLicenseNo) && <div className="text-warning fs-12">{oldUCInfo.profileInfo.businessLicenseNo ? oldUCInfo.profileInfo.businessLicenseNo : '-'}</div>}
                                                                                                                <input type="text" className="form-control" id="val-business-license-no" name="businessLicenseNo" value={formProfile.businessLicenseNo} onChange={handleInputFormProfileNoSpace} placeholder="" required />
                                                                                                                {errors.profileInfo.businessLicenseNo && <div className="text-danger fs-12">{errors.profileInfo.businessLicenseNo}</div>}
                                                                                                            </div> */}
                                                                                                            <input className="form-control" type="file" id="val-business-license-image" value={formProfile.businessLicenseFile} onChange={handleChooseBusinessLicenseFile} accept={ACCEPT_BUSINESS_LICENSE_FILE} required />
                                                                                                            <div className="mt-2">
                                                                                                                {oldUCInfo && hasDifferent(getBusinessLicenseFileURL(editUnifiedCustomer ? editUnifiedCustomer.details : null), getBusinessLicenseFileURL(oldUCInfo))
                                                                                                                    && <>
                                                                                                                        <span className='text-warning fs-13 me-4' onClick={() => openOriginalFile(getBusinessLicenseFileURL(oldUCInfo))}><i className="fa fa-eye me-1"></i>Original File</span>
                                                                                                                    </>}
                                                                                                                {getBusinessLicenseFileURL(editUnifiedCustomer ? editUnifiedCustomer.details : null) && <>
                                                                                                                    <span className='text-info fs-13' onClick={() => getSignedFileURL(getBusinessLicenseFileURL(editUnifiedCustomer ? editUnifiedCustomer.details : null))}><i className="fa fa-eye me-1"></i>File</span>
                                                                                                                </>
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {isRequireAPCLicense && <>
                                                                                                        <div className="form-group mb-3 row">
                                                                                                            <label className="col-lg-4 col-form-label" htmlFor="val-apc-image">Annual Practicing Certificate<br />(APC)<span className="text-danger">*</span></label>
                                                                                                            <div className="col-lg-7">
                                                                                                                <input className="form-control" type="file" id="val-apc-image" name="apcLicenseFile" onChange={handleChooseAPCFile} accept={ACCEPT_BUSINESS_LICENSE_FILE} />
                                                                                                                <div className="mt-2">
                                                                                                                    {oldUCInfo && hasDifferent(getApcFileURL(editUnifiedCustomer ? editUnifiedCustomer.details : null), getApcFileURL(oldUCInfo))
                                                                                                                        && <>
                                                                                                                            <span className='text-warning fs-13 me-4' onClick={() => openOriginalFile(getApcFileURL(oldUCInfo))}><i className="fa fa-eye me-1"></i>Original File</span>
                                                                                                                        </>}
                                                                                                                    {getApcFileURL(editUnifiedCustomer ? editUnifiedCustomer.details : null) && <>
                                                                                                                        <span className='text-info fs-13' onClick={() => getSignedFileURL(getApcFileURL(editUnifiedCustomer ? editUnifiedCustomer.details : null))}><i className="fa fa-eye me-1"></i>File</span>
                                                                                                                    </>}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </>}
                                                                                                    {isRequirePoisonLicense && <>
                                                                                                        <div className="form-group mb-3 row">
                                                                                                            <label className="col-lg-4 col-form-label" htmlFor="val-poison-a-license-image">Poison A License<span className="text-danger">*</span></label>
                                                                                                            <div className="col-lg-7">
                                                                                                                <input className="form-control" type="file" id="val-poison-a-license-image" name="poisonALicenseFile" onChange={handleChoosePoisonALicenseFile} accept={ACCEPT_BUSINESS_LICENSE_FILE} />
                                                                                                                <div className="mt-2">
                                                                                                                    {oldUCInfo && hasDifferent(getPoisonALicenseFileURL(editUnifiedCustomer ? editUnifiedCustomer.details : null), getPoisonALicenseFileURL(oldUCInfo))
                                                                                                                        && <>
                                                                                                                            <span className='text-warning fs-13 me-4' onClick={() => openOriginalFile(getPoisonALicenseFileURL(oldUCInfo))}><i className="fa fa-eye me-1"></i>Original File</span>
                                                                                                                        </>}
                                                                                                                    {getPoisonALicenseFileURL(editUnifiedCustomer ? editUnifiedCustomer.details : null) && <>
                                                                                                                        <span className='text-info fs-13' onClick={() => getSignedFileURL(getPoisonALicenseFileURL(editUnifiedCustomer ? editUnifiedCustomer.details : null))}><i className="fa fa-eye me-1"></i>File</span>
                                                                                                                    </>}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </>}
                                                                                                    <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label text-danger" htmlFor="val-verification">Verification</label>
                                                                                                        <div className="col-lg-7">
                                                                                                            <select className="form-control text-danger bg-disabled" id="val-verification" name="val-verification" value={verification} /* onChange={(e) => chooseVerification(e.target.value)} */ disabled>
                                                                                                                <option value="">N/A</option>
                                                                                                                {VerificationStatusList.map((s, i) => {
                                                                                                                    return (<option value={s.key} key={i}>{s.title}</option>);
                                                                                                                })}
                                                                                                            </select>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {/* <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label text-info" htmlFor="val-access-level">Access Level</label>
                                                                                                        <div className="col-lg-7">
                                                                                                            <select className="form-control text-info" id="val-access-level" name="val-access-level" value={formCustomerData.accessLevel} onChange={(e) => chooseAccessLevel(e.target.value)}>
                                                                                                                <option value="">N/A</option>
                                                                                                                {AccessLevels.ListAvailable().map((s, i) => {
                                                                                                                    return (<option value={s.key} key={i}>{s.name}</option>);
                                                                                                                })}
                                                                                                            </select>
                                                                                                        </div>
                                                                                                    </div> */}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </form>
                                                                                </> : ''}
                                                                                {/* Ref.ID */}
                                                                                {data.key === CustomerTabKeys.REF_ID ? (
                                                                                    <form id="#refIdsForm">
                                                                                        <div className="form-validation">
                                                                                            <div className="row">
                                                                                                {refIds.map((ref, i) => {
                                                                                                    return (<RenderRefId key={ref.id} index={i} onDelete={(e) => {
                                                                                                        e.preventDefault();
                                                                                                        onHandleDeleteRefId(i)
                                                                                                    }} onUpdate={onUpdateRefId} onFind={onFindCustomer} data={ref} vendor={ref.vendor} />);
                                                                                                })}
                                                                                            </div>
                                                                                            {/* Add identity */}
                                                                                            {isEnableAddPOMIdentity() && (<button className="btn btn-primary light btn-sm ms-2" onClick={(e) => {
                                                                                                e.preventDefault();
                                                                                                onAddVietPOMIdentity();
                                                                                            }}>+ Add POM Identity</button>)}
                                                                                            {/* {vendors && vendors.length > 0
                                                                                                && (
                                                                                                    vendors.map((vendor, i) => {
                                                                                                        return (isEnableAddVendorIdentity(vendor) && <button className="btn btn-primary light btn-sm ms-2" onClick={(e) => {
                                                                                                            e.preventDefault();
                                                                                                            onAddIdentity(vendor);
                                                                                                        }} key={i}>{`+ Add ${vendor.companyName} Identity`}</button>);
                                                                                                    })
                                                                                                )} */}
                                                                                        </div>
                                                                                    </form>
                                                                                ) : ''}
                                                                                {/* Sub-users */}
                                                                                {data.key === CustomerTabKeys.SUB_USERS ? <>
                                                                                    <table className="table sub-users-table">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th>#</th>
                                                                                                <th>Account No.</th>
                                                                                                <th>Name</th>
                                                                                                <th>Phone</th>
                                                                                                <th>Email</th>
                                                                                                <th>Role</th>
                                                                                                <th>Action</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {newSubusersKYC && newSubusersKYC.length > 0 ? <>
                                                                                                {newSubusersKYC.map((item, index) => (
                                                                                                    <tr key={index}>
                                                                                                        <td>{index + 1}</td>
                                                                                                        <td><span className='text-black auto-break-word'>{item.accountNo ? item.accountNo : '-'}</span></td>
                                                                                                        <td><span>{item.name ? item.name : '-'}</span></td>
                                                                                                        <td><span>{item.phoneNumber}</span></td>
                                                                                                        <td><span className="text-info">{item.email ? item.email : '-'}</span></td>
                                                                                                        <td><span>{item.role}</span></td>
                                                                                                        <td>-</td>
                                                                                                    </tr>
                                                                                                ))}
                                                                                            </> : <>
                                                                                                {subusers && subusers.length > 0 ? <>
                                                                                                    {subusers.map((item, index) => (
                                                                                                        <tr key={index}>
                                                                                                            <td>{index + 1}</td>
                                                                                                            <td>
                                                                                                                <span onClick={() => { }} className='text-black auto-break-word'>{item.accountNo}</span>
                                                                                                                {isErrorOnSubUser(item) && <><span onClick={() => showToastError(getErrorOnSubUser(item))}><i
                                                                                                                    className="fa fa-exclamation-triangle text-danger ms-1"></i></span></>}
                                                                                                            </td>
                                                                                                            <td><span>{item.name ? item.name : '-'}</span></td>
                                                                                                            <td><span>{item.phoneNumber}</span></td>
                                                                                                            <td><span className="text-info">{item.email ? item.email : '-'}</span></td>
                                                                                                            <td><span>{item.role}</span></td>
                                                                                                            <td>
                                                                                                                <Dropdown className="dropdown custom-dropdown mb-0">
                                                                                                                    <Dropdown.Toggle className="btn sharp btn-primary tp-btn i-false" data-toggle="dropdown">
                                                                                                                        {SVGICON.MenuDot}
                                                                                                                    </Dropdown.Toggle>
                                                                                                                    <Dropdown.Menu className="dropdown-menu dropdown-menu-end">
                                                                                                                        {OptionMenuSubUser.map((st, index) => (<Dropdown.Item key={index}
                                                                                                                            className={`dropdown-item ${st.color}`} onClick={(e) => {
                                                                                                                                e.preventDefault();
                                                                                                                                chooseOptionMenuSubUser(item, st.key);
                                                                                                                            }}>{st.title}</Dropdown.Item>))}
                                                                                                                    </Dropdown.Menu>
                                                                                                                </Dropdown>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    ))}
                                                                                                </> : <>
                                                                                                    <tr>
                                                                                                        <td colSpan={7}><label className='ms-2 mt-4'>No data</label></td>
                                                                                                    </tr>
                                                                                                </>}
                                                                                            </>}
                                                                                        </tbody>
                                                                                    </table>
                                                                                    {/* Add sub-user */}
                                                                                    {isEnableAddSubUser() && <>
                                                                                        <button className="btn btn-primary light btn-sm ms-2" onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            displayDetailsSubUser();
                                                                                        }}>+ Add User</button>
                                                                                    </>}
                                                                                </> : ''}
                                                                                {/* Segments for the each tab */}
                                                                                {data.segments && data.segments.length > 0 ? <>
                                                                                    {data.segments.map((seg, segInd) => (
                                                                                        <div key={seg.key}>
                                                                                            <label className="col-form-label text-menu-label fs-6 pb-0 mt-4" htmlFor="">{seg.name}</label>
                                                                                            <hr />
                                                                                            {/* Business Address */}
                                                                                            {seg.key === CustomerTabKeys.BUSINESS_ADDRESS ? (
                                                                                                <form id="#businessAddressForm">
                                                                                                    <div className='form-validation'>
                                                                                                        <div className='row'>
                                                                                                            <div className='col-xl-6'>
                                                                                                                <div className="form-group mb-3 row">
                                                                                                                    <label className="col-lg-4 col-form-label" htmlFor="val-email">Email<span className="text-danger">*</span></label>
                                                                                                                    <div className="col-lg-7">
                                                                                                                        {oldUCInfo && hasDifferent(formCustomerData.email, oldUCInfo.email) && <div className="text-warning fs-12">{oldUCInfo.email ? oldUCInfo.email : '-'}</div>}
                                                                                                                        <input type="email" className="form-control" id="email" name="email" value={formCustomerData.email ? formCustomerData.email : ''} onChange={handleInputFormInfoNoSpace} placeholder={makeEmailFormatHint()} />
                                                                                                                        {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="form-group mb-3 row">
                                                                                                                    <label className="col-lg-4 col-form-label" htmlFor="val-phone">Contact<span className="text-danger">*</span></label>
                                                                                                                    <div className="col-lg-7">
                                                                                                                        {oldUCInfo && hasDifferent(formCustomerData.phoneNumber, oldUCInfo.phoneNumber) && <div className="text-warning fs-12">{oldUCInfo.phoneNumber}</div>}
                                                                                                                        <input type="tel" className="form-control" id="phone-number" name="phoneNumber" value={formCustomerData.phoneNumber} onChange={handleInputFormInfoNoSpace} placeholder={getPhoneNumberFormatHint()} />
                                                                                                                        {errors.phoneNumber && <div className="text-danger fs-12">{errors.phoneNumber}</div>}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="form-group mb-3 row">
                                                                                                                    <label className="col-lg-4 col-form-label" htmlFor="val-profile-country">Country<span className="text-danger">*</span></label>
                                                                                                                    <div className="col-lg-7">
                                                                                                                        {oldUCInfo && hasDifferent(formProfile.country, oldUCInfo.profileInfo.country) && <div className="text-warning fs-12">{oldUCInfo.profileInfo.country}</div>}
                                                                                                                        <select className={isSuperAdmin ? `form-control` : `form-control bg-disabled`} id="val-profile-country" name="val-profile-country" value={formProfile.locCodes.countryCode} onChange={(e) => chooseProfileCountry(e.target.value)} disabled={!isSuperAdmin} required>
                                                                                                                            {/* <option value="">Please select</option> */}
                                                                                                                            {countries.length > 0 && (
                                                                                                                                countries.map((country, i) => {
                                                                                                                                    return (<option value={country.code} key={i}>{country.name}</option>);
                                                                                                                                })
                                                                                                                            )}
                                                                                                                        </select>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                {/* <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="val-region-group">Region Group<span className="text-danger">*</span></label>
                                                                                                        <div className="col-lg-7">
                                                                                                            {oldUCInfo && hasDifferent(formCustomerData.regionGroup, oldUCInfo.regionGroup) && <div className="text-warning fs-12">{RegionsGroupDefault.GetName(oldUCInfo.regionGroup)}</div>}
                                                                                                            <select className="form-control" id="val-region-group" name="val-region-group" value={formCustomerData.regionGroup} onChange={(e) => chooseRegionGroup(e.target.value)} required>
                                                                                                                <option value="">Please select</option>
                                                                                                                {regionsGroup.map((group, i) => { return (<option value={group.keyword} key={i}>{group.name}</option>); })}
                                                                                                            </select>
                                                                                                            {errors.regionGroup && <div className="text-danger fs-12">{errors.regionGroup}</div>}
                                                                                                        </div>
                                                                                                    </div> */}
                                                                                                            </div>
                                                                                                            <div className="col-xl-6">
                                                                                                                <div className="form-group mb-3 row">
                                                                                                                    <label className="col-lg-4 col-form-label" htmlFor="profile-address">Address<span className="text-danger">*</span></label>
                                                                                                                    <div className='col-lg-7'>
                                                                                                                        {oldUCInfo && hasDifferent(formProfile.address, oldUCInfo.profileInfo.address) && <div className="text-warning fs-12">{oldUCInfo.profileInfo.address}</div>}
                                                                                                                        <input type="text" className="form-control" id="profile-address" name="address" value={formProfile.address} onChange={handleInputFormProfile} placeholder="" required />
                                                                                                                        {errors.profileInfo.address && <div className="text-danger fs-12">{errors.profileInfo.address}</div>}
                                                                                                                        <label className="col-lg-4 col-form-label mt-2" htmlFor="profile-address-2">Address Line 2</label>
                                                                                                                        {oldUCInfo && hasDifferent(formProfile.address2, oldUCInfo.profileInfo.address2) && <div className="text-warning fs-12">{oldUCInfo.profileInfo.address2}</div>}
                                                                                                                        <input type="text" className="form-control" id="profile-address-2" name="address2" value={formProfile.address2 ? formProfile.address2 : ''} onChange={handleInputFormProfile} placeholder="" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                {enableEnterManualProfileAddress ? <>
                                                                                                                    <div>
                                                                                                                        <div className="form-group mb-3 row">
                                                                                                                            <label className="col-lg-4 col-form-label" htmlFor="val-profile-city">City<span className="text-danger">*</span></label>
                                                                                                                            <div className='col-lg-7'>
                                                                                                                                {oldUCInfo && hasDifferent(formProfile.city, oldUCInfo.profileInfo.city) && <div className="text-warning fs-12">{oldUCInfo.profileInfo.city}</div>}
                                                                                                                                <input type="text" className="form-control" id='profile-city' name="city" value={formProfile.city} onChange={handleInputFormProfile} placeholder="" />
                                                                                                                                {errors.profileInfo.city && <div className="text-danger fs-12">{errors.profileInfo.city}</div>}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="form-group mb-3 row">
                                                                                                                            <label className="col-lg-4 col-form-label" htmlFor="val-shipping-state">State<span className="text-danger">*</span></label>
                                                                                                                            <div className='col-lg-7'>
                                                                                                                                {oldUCInfo && hasDifferent(formProfile.state, oldUCInfo.profileInfo.state) && <div className="text-warning fs-12">{oldUCInfo.profileInfo.state}</div>}
                                                                                                                                <select className="form-control" value={formProfile.locCodes.stateCode} onChange={(e) => chooseProfileState(e.target.value)} required>
                                                                                                                                    <option value="">Please select</option>
                                                                                                                                    {states.length > 0 && (
                                                                                                                                        states.map((st, i) => {
                                                                                                                                            return (<option value={st.CodeState} key={i}>{st.NameState}</option>);
                                                                                                                                        })
                                                                                                                                    )}
                                                                                                                                </select>
                                                                                                                                {errors.profileInfo.state && <div className="text-danger fs-12">{errors.profileInfo.state}</div>}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="form-group mb-3 row">
                                                                                                                            <label className="col-lg-4 col-form-label" htmlFor="val-shipping-postal-code">Zip/Postal Code<span className="text-danger">*</span></label>
                                                                                                                            <div className='col-lg-7'>
                                                                                                                                {oldUCInfo && hasDifferent(formProfile.postalCode, oldUCInfo.profileInfo.postalCode) && <div className="text-warning fs-12">{oldUCInfo.profileInfo.postalCode}</div>}
                                                                                                                                <input type="text" className="form-control" id='profile-postal-code' name="postalCode" value={formProfile.postalCode} onChange={handleInputFormProfile} placeholder="" />
                                                                                                                                {errors.profileInfo.postalCode && <div className="text-danger fs-12">{errors.profileInfo.postalCode}</div>}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </> : <>
                                                                                                                    <div>
                                                                                                                        <label className="col-form-label" htmlFor="val-profile-city">City<span className="text-danger">*</span></label>
                                                                                                                        {oldUCInfo && hasDifferent(formProfile.city, oldUCInfo.profileInfo.city) && <div className="text-warning fs-12">{oldUCInfo.profileInfo.city ? oldUCInfo.profileInfo.city : '-'}</div>}
                                                                                                                        <div className="mb-2" >
                                                                                                                            <select className="form-control" value={formProfile.locCodes.cityCode} onChange={(e) => chooseProfileCity(e.target.value)} required>
                                                                                                                                <option value="">Please select</option>
                                                                                                                                {cities.length > 0 && (
                                                                                                                                    cities.map((city, i) => {
                                                                                                                                        return (<option value={city.CodeCity} key={i}>{city.NameCity}</option>);
                                                                                                                                    })
                                                                                                                                )}
                                                                                                                            </select>
                                                                                                                            {errors.profileInfo.city && <div className="text-danger fs-12">{errors.profileInfo.city}</div>}
                                                                                                                        </div>
                                                                                                                        <label className="col-form-label" htmlFor="val-profile-district">District</label>
                                                                                                                        {oldUCInfo && hasDifferent(formProfile.district, oldUCInfo.profileInfo.district) && <div className="text-warning fs-12">{oldUCInfo.profileInfo.district ? formatDistrictWard(oldUCInfo.profileInfo.district, LocationFormat.DISTRICT_NUMBER_PREFIX_VN) : '-'}</div>}
                                                                                                                        <select className="form-control mb-2" value={formProfile.locCodes.districtCode} onChange={(e) => chooseProfileDistrict(e.target.value)}>
                                                                                                                            <option value="">Please select</option>
                                                                                                                            {districts.length > 0 && (
                                                                                                                                (districts.map((dis, i) => {
                                                                                                                                    return (<option value={dis.CodeDistrict} key={i}>{formatDistrictWard(dis.NameDistrict, LocationFormat.DISTRICT_NUMBER_PREFIX_VN)}</option>);
                                                                                                                                }))
                                                                                                                            )}
                                                                                                                        </select>
                                                                                                                        <label className="col-form-label" htmlFor="val-email">Ward</label>
                                                                                                                        {oldUCInfo && hasDifferent(formProfile.ward, oldUCInfo.profileInfo.ward) && <div className="text-warning fs-12">{oldUCInfo.profileInfo.ward ? formatDistrictWard(oldUCInfo.profileInfo.ward, LocationFormat.WARD_NUMBER_PREFIX_VN) : '-'}</div>}
                                                                                                                        <select className="form-control" value={formProfile.locCodes.wardCode} onChange={(e) => chooseProfileWard(e.target.value)}>
                                                                                                                            <option value="">Please select</option>
                                                                                                                            {wards.length > 0 && (
                                                                                                                                (wards.map((ward, i) => {
                                                                                                                                    return (<option value={ward.CodeWard} key={i}>{formatDistrictWard(ward.NameWard, LocationFormat.WARD_NUMBER_PREFIX_VN)}</option>);
                                                                                                                                }))
                                                                                                                            )}
                                                                                                                        </select>
                                                                                                                    </div>
                                                                                                                </>}

                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </form>
                                                                                            ) : ''}
                                                                                            {/* Billing Address */}
                                                                                            {seg.key === CustomerTabKeys.BILLING_ADDRESS ? (
                                                                                                <form id="#billingForm">
                                                                                                    <div className="form-validation">
                                                                                                        <div className="row">
                                                                                                            <div className="col-xl-6">
                                                                                                                <div className="form-group mb-3 row">
                                                                                                                    <label className="col-lg-4 col-form-label" htmlFor="val-billing-name">Name<span
                                                                                                                        className="text-danger">*</span></label>
                                                                                                                    <div className="col-lg-7">
                                                                                                                        {oldUCInfo && hasDifferent(formBilling.name, oldUCInfo.billingInfo.name) && <div
                                                                                                                            className="text-warning fs-12">{oldUCInfo.billingInfo.name}</div>}
                                                                                                                        <input type="text" className="form-control" id="val-billing-name" name="name"
                                                                                                                            value={formBilling.name} onChange={handleInputFormBilling} placeholder=""
                                                                                                                            required />
                                                                                                                        {errors.billingInfo.name && <div className="text-danger fs-12">{errors.billingInfo.name}
                                                                                                                        </div>}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="form-group row">
                                                                                                                    <label className="col-lg-4 col-form-label" htmlFor="val-billing-tax-id">Tax ID<span
                                                                                                                        className="text-danger">*</span></label>
                                                                                                                    <div className="col-lg-7">
                                                                                                                        {oldUCInfo && hasDifferent(formBilling.taxCode, oldUCInfo.billingInfo.taxCode) && <div
                                                                                                                            className="text-warning fs-12">{oldUCInfo.billingInfo.taxCode}</div>}
                                                                                                                        <input type="text" className="form-control" id="val-billing-tax-id" name="taxCode"
                                                                                                                            value={formBilling.taxCode} onChange={handleInputFormBillingNoSpace} placeholder=""
                                                                                                                            required />
                                                                                                                        {errors.billingInfo.taxCode && <div className="text-danger fs-12">
                                                                                                                            {errors.billingInfo.taxCode}</div>}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="col-xl-6">
                                                                                                                <div className="form-group mb-3 row">
                                                                                                                    <label className="col-lg-4 col-form-label" htmlFor="val-billing-address">Address<span
                                                                                                                        className="text-danger">*</span></label>
                                                                                                                    <div className="col-lg-7">
                                                                                                                        {oldUCInfo && hasDifferent(formBilling.address, oldUCInfo.billingInfo.address) && <div
                                                                                                                            className="text-warning fs-12">{oldUCInfo.billingInfo.address}</div>}
                                                                                                                        <input type="text" className="form-control" id="val-billing-address" name="address"
                                                                                                                            value={formBilling.address} onChange={handleInputFormBilling} placeholder=""
                                                                                                                            required />
                                                                                                                        {errors.billingInfo.address && <div className="text-danger fs-12">
                                                                                                                            {errors.billingInfo.address}</div>}
                                                                                                                        <label className="col-lg-4 col-form-label mt-2" htmlFor="val-billing-address-2">Address
                                                                                                                            Line 2</label>
                                                                                                                        {oldUCInfo && hasDifferent(formBilling.address2, oldUCInfo.billingInfo.address2) && <div
                                                                                                                            className="text-warning fs-12">{oldUCInfo.billingInfo.address2}</div>}
                                                                                                                        <input type="text" className="form-control" id="val-billing-address-2" name="address2"
                                                                                                                            value={formBilling.address2 ? formBilling.address2 : ''} onChange={handleInputFormBilling} placeholder="" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                {enableEnterManualProfileAddress ? (
                                                                                                                    <div>
                                                                                                                        <div className="form-group mb-3 row">
                                                                                                                            <label className="col-lg-4 col-form-label" htmlFor="val-billing-city">City<span
                                                                                                                                className="text-danger">*</span></label>
                                                                                                                            <div className="col-lg-7">
                                                                                                                                {oldUCInfo && hasDifferent(formBilling.city, oldUCInfo.billingInfo.city) && <div
                                                                                                                                    className="text-warning fs-12">{oldUCInfo.billingInfo.city}</div>}
                                                                                                                                <input type="text" className="form-control" id="val-billing-city" name="city"
                                                                                                                                    value={formBilling && formBilling.city ? formBilling.city : ''}
                                                                                                                                    onChange={handleInputFormBilling} placeholder="" />
                                                                                                                                {errors.billingInfo.city && <div className="text-danger fs-12">
                                                                                                                                    {errors.billingInfo.city}</div>}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="form-group mb-3 row">
                                                                                                                            <label className="col-lg-4 col-form-label" htmlFor="val-billing-state">State<span
                                                                                                                                className="text-danger">*</span></label>
                                                                                                                            <div className="col-lg-7">
                                                                                                                                {oldUCInfo && hasDifferent(formBilling.state, oldUCInfo.billingInfo.state) && <div
                                                                                                                                    className="text-warning fs-12">{oldUCInfo.billingInfo.state}</div>}
                                                                                                                                <select className="form-control" value={formBilling.locCodes.stateCode}
                                                                                                                                    onChange={(e) => chooseBillingState(e.target.value)} required>
                                                                                                                                    <option value="">Please select</option>
                                                                                                                                    {billingStates.length > 0 && (
                                                                                                                                        billingStates.map((st, i) => {
                                                                                                                                            return (<option value={st.CodeState} key={i}>{st.NameState}</option>);
                                                                                                                                        })
                                                                                                                                    )}
                                                                                                                                </select>
                                                                                                                                {errors.billingInfo.state && <div className="text-danger fs-12">
                                                                                                                                    {errors.billingInfo.state}</div>}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="form-group mb-3 row">
                                                                                                                            <label className="col-lg-4 col-form-label" htmlFor="val-billing-postal-code">Zip/Postal
                                                                                                                                Code<span className="text-danger">*</span></label>
                                                                                                                            <div className="col-lg-7">
                                                                                                                                {oldUCInfo && hasDifferent(formBilling.postalCode, oldUCInfo.billingInfo.postalCode)
                                                                                                                                    && <div className="text-warning fs-12">{oldUCInfo.billingInfo.postalCode}</div>}
                                                                                                                                <input type="text" className="form-control" id="val-billing-postal-code"
                                                                                                                                    name="postalCode" value={formBilling.postalCode ? formBilling.postalCode : ''}
                                                                                                                                    onChange={handleInputFormBilling} placeholder="" required />
                                                                                                                                {errors.billingInfo.postalCode && <div className="text-danger fs-12">
                                                                                                                                    {errors.billingInfo.postalCode}</div>}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                ) : (
                                                                                                                    <div>
                                                                                                                        <div className="form-group mb-3 row">
                                                                                                                            <label className="col-lg-4 col-form-label" htmlFor="val-billing-city">City<span
                                                                                                                                className="text-danger">*</span></label>
                                                                                                                            <div className="col-lg-7">
                                                                                                                                {oldUCInfo && hasDifferent(formBilling.city, oldUCInfo.billingInfo.city) && <div
                                                                                                                                    className="text-warning fs-12">{oldUCInfo.billingInfo.city ?
                                                                                                                                        oldUCInfo.billingInfo.city : '-'}</div>}
                                                                                                                                <select className="form-control" value={formBilling.locCodes &&
                                                                                                                                    formBilling.locCodes.cityCode ? formBilling.locCodes.cityCode : ''}
                                                                                                                                    onChange={(e) => chooseBillingCity(e.target.value)} required>
                                                                                                                                    <option value="">Please select</option>
                                                                                                                                    {billingCities.length > 0 && (
                                                                                                                                        billingCities.map((city, i) => {
                                                                                                                                            return (<option value={city.CodeCity} key={i}>{city.NameCity}</option>);
                                                                                                                                        })
                                                                                                                                    )}
                                                                                                                                </select>
                                                                                                                                {errors.billingInfo.city && <div className="text-danger fs-12">
                                                                                                                                    {errors.billingInfo.city}</div>}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="form-group mb-3 row">
                                                                                                                            <label className="col-lg-4 col-form-label"
                                                                                                                                htmlFor="val-billing-district">District</label>
                                                                                                                            <div className="col-lg-7">
                                                                                                                                {oldUCInfo && hasDifferent(formBilling.district, oldUCInfo.billingInfo.district) &&
                                                                                                                                    <div className="text-warning fs-12">{oldUCInfo.billingInfo.district ?
                                                                                                                                        formatDistrictWard(oldUCInfo.billingInfo.district,
                                                                                                                                            LocationFormat.DISTRICT_NUMBER_PREFIX_VN) : '-'}</div>}
                                                                                                                                <select className="form-control mb-2" value={formBilling.locCodes &&
                                                                                                                                    formBilling.locCodes.districtCode ? formBilling.locCodes.districtCode : ''}
                                                                                                                                    onChange={(e) => chooseBillingDistrict(e.target.value)}>
                                                                                                                                    <option value="">Please select</option>
                                                                                                                                    {billingDistricts.length > 0 && (
                                                                                                                                        (billingDistricts.map((dis, i) => {
                                                                                                                                            return (<option value={dis.CodeDistrict} key={i}>
                                                                                                                                                {formatDistrictWard(dis.NameDistrict,
                                                                                                                                                    LocationFormat.DISTRICT_NUMBER_PREFIX_VN)}</option>);
                                                                                                                                        }))
                                                                                                                                    )}
                                                                                                                                </select>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="form-group mb-3 row">
                                                                                                                            <label className="col-lg-4 col-form-label" htmlFor="val-billing-ward">Ward</label>
                                                                                                                            <div className="col-lg-7">
                                                                                                                                {oldUCInfo && hasDifferent(formBilling.ward, oldUCInfo.billingInfo.ward) && <div
                                                                                                                                    className="text-warning fs-12">{oldUCInfo.billingInfo.ward ?
                                                                                                                                        formatDistrictWard(oldUCInfo.billingInfo.ward,
                                                                                                                                            LocationFormat.WARD_NUMBER_PREFIX_VN) : '-'}</div>}
                                                                                                                                <select className="form-control" value={formBilling.locCodes &&
                                                                                                                                    formBilling.locCodes.wardCode ? formBilling.locCodes.wardCode : ''}
                                                                                                                                    onChange={(e) => chooseBillingWard(e.target.value)}>
                                                                                                                                    <option value="">Please select</option>
                                                                                                                                    {billingWards.length > 0 && (
                                                                                                                                        (billingWards.map((ward, i) => {
                                                                                                                                            return (<option value={ward.CodeWard} key={i}>{formatDistrictWard(ward.NameWard,
                                                                                                                                                LocationFormat.WARD_NUMBER_PREFIX_VN)}</option>);
                                                                                                                                        }))
                                                                                                                                    )}
                                                                                                                                </select>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                )}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </form>
                                                                                            ) : ''}
                                                                                            {/* Shipping Address*/}
                                                                                            {seg.key === CustomerTabKeys.SHIPPING_ADDRESS ? (
                                                                                                <form id="#shippingForm" key={segInd}>
                                                                                                    <div className="form-validation">
                                                                                                        <div className="row">
                                                                                                            <div className="col-xl-6">
                                                                                                                <div className="form-group mb-3 row">
                                                                                                                    <label className="col-lg-4 col-form-label" htmlFor="val-shipping-contact-name">Contact
                                                                                                                        Name<span className="text-danger">*</span></label>
                                                                                                                    <div className="col-lg-7">
                                                                                                                        {oldUCInfo && hasDifferent(formShipping.contactName, oldUCInfo.shippingInfo.contactName)
                                                                                                                            && <div className="text-warning fs-12">{oldUCInfo.shippingInfo.contactName}</div>}
                                                                                                                        <input type="text" className="form-control" id="val-shipping-contact-name"
                                                                                                                            name="contactName" value={formShipping.contactName}
                                                                                                                            onChange={handleInputFormShipping} placeholder="" required />
                                                                                                                        {errors.shippingInfo.contactName && <div className="text-danger fs-12">
                                                                                                                            {errors.shippingInfo.contactName}</div>}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="form-group mb-3 row">
                                                                                                                    <label className="col-lg-4 col-form-label" htmlFor="val-shipping-contact-number">Contact
                                                                                                                        Number<span className="text-danger">*</span></label>
                                                                                                                    <div className="col-lg-7">
                                                                                                                        {oldUCInfo && hasDifferent(formShipping.contactNumber,
                                                                                                                            oldUCInfo.shippingInfo.contactNumber) && <div className="text-warning fs-12">
                                                                                                                                {oldUCInfo.shippingInfo.contactNumber}</div>}
                                                                                                                        <input type="tel" className="form-control" id="val-shipping-contact-number"
                                                                                                                            name="contactNumber" value={formShipping.contactNumber}
                                                                                                                            onChange={handleInputFormShippingNoSpace} placeholder={getPhoneNumberFormatHint()} required />
                                                                                                                        {errors.shippingInfo.contactNumber && <div className="text-danger fs-12">
                                                                                                                            {errors.shippingInfo.contactNumber}</div>}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="col-xl-6">
                                                                                                                <div className="form-group mb-3 row">
                                                                                                                    <label className="col-lg-4 col-form-label" htmlFor="val-shipping-address">Address<span
                                                                                                                        className="text-danger">*</span></label>
                                                                                                                    <div className="col-lg-7">
                                                                                                                        {oldUCInfo && hasDifferent(formShipping.address, oldUCInfo.shippingInfo.address) && <div
                                                                                                                            className="text-warning fs-12">{oldUCInfo.shippingInfo.address}</div>}
                                                                                                                        <input type="text" className="form-control" id="val-shipping-address" name="address"
                                                                                                                            value={formShipping.address} onChange={handleInputFormShipping} placeholder=""
                                                                                                                            required />
                                                                                                                        {errors.shippingInfo.address && <div className="text-danger fs-12">
                                                                                                                            {errors.shippingInfo.address}</div>}
                                                                                                                        <label className="col-lg-4 col-form-label mt-2" htmlFor="val-shipping-address">Address
                                                                                                                            Line 2</label>
                                                                                                                        {oldUCInfo && hasDifferent(formShipping.address2, oldUCInfo.shippingInfo.address2) &&
                                                                                                                            <div className="text-warning fs-12">{oldUCInfo.shippingInfo.address2}</div>}
                                                                                                                        <input type="text" className="form-control" id="val-shipping-address-2" name="address2"
                                                                                                                            value={formShipping.address2 ? formShipping.address2 : ''} onChange={handleInputFormShipping} placeholder="" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                {enableEnterManualProfileAddress ? (
                                                                                                                    <div>
                                                                                                                        <div className="form-group mb-3 row">
                                                                                                                            <label className="col-lg-4 col-form-label" htmlFor="val-shipping-city">City<span
                                                                                                                                className="text-danger">*</span></label>
                                                                                                                            <div className="col-lg-7">
                                                                                                                                {oldUCInfo && hasDifferent(formShipping.city, oldUCInfo.shippingInfo.city) && <div
                                                                                                                                    className="text-warning fs-12">{oldUCInfo.shippingInfo.city}</div>}
                                                                                                                                <input type="text" className="form-control" id="val-shipping-city" name="city"
                                                                                                                                    value={formShipping.city} onChange={handleInputFormShipping} placeholder="" />
                                                                                                                                {errors.shippingInfo.city && <div className="text-danger fs-12">
                                                                                                                                    {errors.shippingInfo.city}</div>}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="form-group mb-3 row">
                                                                                                                            <label className="col-lg-4 col-form-label" htmlFor="val-shipping-state">State<span
                                                                                                                                className="text-danger">*</span></label>
                                                                                                                            <div className="col-lg-7">
                                                                                                                                {oldUCInfo && hasDifferent(formShipping.state, oldUCInfo.shippingInfo.state) && <div
                                                                                                                                    className="text-warning fs-12">{oldUCInfo.shippingInfo.state}</div>}
                                                                                                                                <select className="form-control" value={formShipping.locCodes.stateCode}
                                                                                                                                    onChange={(e) => chooseShippingState(e.target.value)} required>
                                                                                                                                    <option value="">Please select</option>
                                                                                                                                    {shippingStates.length > 0 && (
                                                                                                                                        shippingStates.map((st, i) => {
                                                                                                                                            return (<option value={st.CodeState} key={i}>{st.NameState}</option>);
                                                                                                                                        })
                                                                                                                                    )}
                                                                                                                                </select>
                                                                                                                                {errors.shippingInfo.state && <div className="text-danger fs-12">
                                                                                                                                    {errors.shippingInfo.state}</div>}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="form-group mb-3 row">
                                                                                                                            <label className="col-lg-4 col-form-label" htmlFor="val-shipping-postal-code">Zip/Postal
                                                                                                                                Code<span className="text-danger">*</span></label>
                                                                                                                            <div className="col-lg-7">
                                                                                                                                {oldUCInfo && hasDifferent(formShipping.postalCode,
                                                                                                                                    oldUCInfo.shippingInfo.postalCode) && <div className="text-warning fs-12">
                                                                                                                                        {oldUCInfo.shippingInfo.postalCode}</div>}
                                                                                                                                <input type="text" className="form-control" id="val-shipping-postal-code"
                                                                                                                                    name="postalCode" value={formShipping.postalCode ? formShipping.postalCode : ''
                                                                                                                                    } onChange={handleInputFormShipping} placeholder="" required />
                                                                                                                                {errors.shippingInfo.postalCode && <div className="text-danger fs-12">
                                                                                                                                    {errors.shippingInfo.postalCode}</div>}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                ) : (
                                                                                                                    <div>
                                                                                                                        <div className="form-group mb-3 row">
                                                                                                                            <label className="col-lg-4 col-form-label" htmlFor="val-shipping-city">City<span
                                                                                                                                className="text-danger">*</span></label>
                                                                                                                            <div className="col-lg-7">
                                                                                                                                {oldUCInfo && hasDifferent(formShipping.city, oldUCInfo.shippingInfo.city) && <div
                                                                                                                                    className="text-warning fs-12">{oldUCInfo.shippingInfo.city ?
                                                                                                                                        oldUCInfo.shippingInfo.city : '-'}</div>}
                                                                                                                                <select className="form-control" value={formShipping.locCodes &&
                                                                                                                                    formShipping.locCodes.cityCode ? formShipping.locCodes.cityCode : ''}
                                                                                                                                    onChange={(e) => chooseShippingCity(e.target.value)} required>
                                                                                                                                    <option value="">Please select</option>
                                                                                                                                    {shippingCities.length > 0 && (
                                                                                                                                        shippingCities.map((city, i) => {
                                                                                                                                            return (<option value={city.CodeCity} key={i}>{city.NameCity}</option>);
                                                                                                                                        })
                                                                                                                                    )}
                                                                                                                                </select>
                                                                                                                                {errors.shippingInfo.city && <div className="text-danger fs-12">
                                                                                                                                    {errors.shippingInfo.city}</div>}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="form-group mb-3 row">
                                                                                                                            <label className="col-lg-4 col-form-label" htmlFor="val-shipping-district">District<span
                                                                                                                                className="text-danger">*</span></label>
                                                                                                                            <div className="col-lg-7">
                                                                                                                                {oldUCInfo && hasDifferent(formShipping.district, oldUCInfo.shippingInfo.district)
                                                                                                                                    && <div className="text-warning fs-12">{oldUCInfo.shippingInfo.district ?
                                                                                                                                        formatDistrictWard(oldUCInfo.shippingInfo.district,
                                                                                                                                            LocationFormat.DISTRICT_NUMBER_PREFIX_VN) : '-'}</div>}
                                                                                                                                <select className="form-control" value={formShipping.locCodes &&
                                                                                                                                    formShipping.locCodes.districtCode ? formShipping.locCodes.districtCode : ''}
                                                                                                                                    onChange={(e) => chooseShippingDistrict(e.target.value)} required>
                                                                                                                                    <option value="">Please select</option>
                                                                                                                                    {shippingDistricts.length > 0 && (
                                                                                                                                        (shippingDistricts.map((dis, i) => {
                                                                                                                                            return (<option value={dis.CodeDistrict} key={i}>
                                                                                                                                                {formatDistrictWard(dis.NameDistrict,
                                                                                                                                                    LocationFormat.DISTRICT_NUMBER_PREFIX_VN)}</option>);
                                                                                                                                        }))
                                                                                                                                    )}
                                                                                                                                </select>
                                                                                                                                {errors.shippingInfo.district && <div className="text-danger fs-12">
                                                                                                                                    {errors.shippingInfo.district}</div>}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="form-group mb-3 row">
                                                                                                                            <label className="col-lg-4 col-form-label" htmlFor="val-shipping-ward">Ward<span
                                                                                                                                className="text-danger">*</span></label>
                                                                                                                            <div className="col-lg-7">
                                                                                                                                {oldUCInfo && hasDifferent(formShipping.ward, oldUCInfo.shippingInfo.ward) && <div
                                                                                                                                    className="text-warning fs-12">{oldUCInfo.shippingInfo.ward ?
                                                                                                                                        formatDistrictWard(oldUCInfo.shippingInfo.ward,
                                                                                                                                            LocationFormat.WARD_NUMBER_PREFIX_VN) : '-'}</div>}
                                                                                                                                <select className="form-control" value={formShipping.locCodes &&
                                                                                                                                    formShipping.locCodes.wardCode ? formShipping.locCodes.wardCode : ''}
                                                                                                                                    onChange={(e) => chooseShippingWard(e.target.value)} required>
                                                                                                                                    <option value="">Please select</option>
                                                                                                                                    {shippingWards.length > 0 && (
                                                                                                                                        (shippingWards.map((ward, i) => {
                                                                                                                                            return (<option value={ward.CodeWard} key={i}>{formatDistrictWard(ward.NameWard,
                                                                                                                                                LocationFormat.WARD_NUMBER_PREFIX_VN)}</option>);
                                                                                                                                        }))
                                                                                                                                    )}
                                                                                                                                </select>
                                                                                                                                {errors.shippingInfo.ward && <div className="text-danger fs-12">
                                                                                                                                    {errors.shippingInfo.ward}</div>}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                )}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </form>
                                                                                            ) : ''}
                                                                                        </div>
                                                                                    ))}
                                                                                    <hr />
                                                                                    <div className="row">
                                                                                        <div className="col-xl-6">
                                                                                        </div>
                                                                                        <div className="col-xl-6">
                                                                                            <div className="form-group mb-3 row">
                                                                                                <label className="col-lg-4 col-form-label text-muted" htmlFor="val-note">Note</label>
                                                                                                <div className="col-lg-7">
                                                                                                    <textarea type="text" rows="3" className="form-control" id="val-note" name="val-note" value={note ? note : ''}
                                                                                                        onChange={(e) => setNote(e.target.value)} ></textarea>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </> : <></>}
                                                                            </Tab.Pane>
                                                                        ))}
                                                                    </Tab.Content>
                                                                </Tab.Container>
                                                            </div>
                                                        </Card.Body>
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Card>
                                        </Tab.Container>
                                    </Col>
                                </Row>
                                <div className="form-group row">
                                    <div className="col-xl-12 text-end">
                                        {editUnifiedCustomer && editUnifiedCustomer.id && editUnifiedCustomer.verification !== VerificationStatus.APPROVED && (<span className="mx-sm-3"><button className="btn btn-danger" onClick={handleApprove}>Approve</button></span>)}
                                        {editUnifiedCustomer && editUnifiedCustomer.requestVerifyInfoId && editUnifiedCustomer.requestVerifyInfoId !== '' ? <>
                                            <span className="mx-sm-3"><button className="btn btn-danger" onClick={handleRejectChanges}>Reject</button></span>
                                            <button className="btn btn-primary" onClick={handleSubmitInfo}>Approve Changes</button></>
                                            : <>
                                                <button className="btn btn-primary" onClick={handleSubmitInfo}>Update Customer</button>
                                            </>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {loading && (<div className="dialog-blur">{<Preloader />}</div>)}
                {displayFileURL && (
                    <div className="dialog-blur">
                        <div className="dialog-content">
                            <div className="text-end">
                                <span className='me-5'>
                                    <a onClick={closeToggle} href={displayFileURL} target='blank' className='text-info'><i className="fa fa-download"></i>Download File</a>
                                </span>
                                <span onClick={closeToggle} className='text-black'><i className="fa fa-times"></i> Close</span>
                            </div>
                            <hr />
                            <img src={displayFileURL} alt="" />
                        </div>
                    </div>
                )}
                <AddSubUser ref={subuserRef} onSave={handleSaveSubUser} />
            </div >
            <PopupConfirmation ref={deleteSubUserConfirmation} onConfirm={handleDeleteSubUser} />
        </>
    )
}

export default EditCustomer;