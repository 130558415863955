/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MainPagetitle from '../../layouts/MainPagetitle';
import { Card, Col, Nav, Row, Tab } from 'react-bootstrap';
import {
    CountryAvailable, CountryCodes, QueryParamsKey, UserGroupDefault,
    VendorIdDefault, VerificationStatusList,
    CustomerTabKeys, AllTabsCustomerForVendor, ACCEPT_BUSINESS_LICENSE_FILE, RegionsGroupDefault, LocationFormat,
    DOCS_FILE_EXTENSION,
    NatureOfBusinessDefault,
    DesignationRequireLicenseFile,
    RoleOfSubUserDefault,
    PrefixDefault,
    SpecialtyDefault
} from '../../constant/AppConstant';
import locationData from '../../../assets/location-vn.json';
import statesMYData from '../../../assets/state-my.json';
import {
    filterCities, filterDistricts, filterStates, filterWards, findCityByCode, findCountryByCode,
    findDistrictByCode, findStateByCode, findWardByCode, formatDistrictWard
} from '../../../helper/location-helper';
import { CustomerInfoModel, FormDataRequest } from '../../models/customer-info-model';
import { BillingInfoModel, LocCodesModel, ProfileInfoModel, ShippingInfoModel } from '../../models/profile-model';
import { makeEmailFormatHint, makeMobileNumberFormatHint, reFormatPhoneNumber, showToastError, showToastSuccess, showToastWarning, validateEmail, validatePhoneNumber } from '../../../helper/helper';
import VendorsService from '../../../services/VendorsService';
import Preloader from '../../layouts/Preloader';
import AssetsService from '../../../services/AssetsService';
import { isRoleAdmin, userDetails } from '../../../services/AuthService';
import { TextApp, TextKey } from '../../../assets/text-app';
import { UnifiedCustomerModel } from '../../models/unified-customer.model';

const EditVendorCustomer = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const countries = CountryAvailable;
    const initialized = useRef(false);
    const isAdmin = isRoleAdmin();
    const userLogged = userDetails();
    const [prefixList, setPrefixList] = useState(PrefixDefault);
    const [userGroup, setUserGroup] = useState(UserGroupDefault.ListAvailable());
    const [specialtyList, setSpecialtyList] = useState(SpecialtyDefault.ListAvailable());
    const [regionsGroup, setRegionsGroup] = useState([]);
    const [natureOfBusinessGroup, setNatureOfBusinessGroup] = useState(NatureOfBusinessDefault.ListAvailable());
    const [loading, setLoading] = useState(false);
    const [enableEnterManualProfileAddress, setEnableEnterManualProfileAddress] = useState(true);
    // const [enableEnterManualShippingAddress, setEnableEnterManualShippingAddress] = useState(false);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [wards, setWards] = useState([]);
    // Billing location
    const [billingStates, setBillingStates] = useState([]);
    const [billingCities, setBillingCities] = useState([]);
    const [billingDistricts, setBillingDistricts] = useState([]);
    const [billingWards, setBillingWards] = useState([]);
    // Shipping location
    const [shippingStates, setShippingStates] = useState([]);
    const [shippingCities, setShippingCities] = useState([]);
    const [shippingDistricts, setShippingDistricts] = useState([]);
    const [shippingWards, setShippingWards] = useState([]);
    const [tabsCustomerInfo, setTabsCustomerInfo] = useState(AllTabsCustomerForVendor);
    // Vendors
    const [vendors, setVendors] = useState([]);
    // Form
    let errorObj = new CustomerInfoModel();
    const [errors, setErrors] = useState(errorObj);
    const [customerDetails, setCustomerDetails] = useState(null);
    const [formCustomerData, setFormCustomerData] = useState(new CustomerInfoModel(userLogged && userLogged.vendorId ? userLogged.vendorId : VendorIdDefault));
    const [formProfile, setFormProfile] = useState(new ProfileInfoModel());
    const [formBilling, setFormBilling] = useState(new BillingInfoModel());
    const [formShipping, setFormShipping] = useState(new ShippingInfoModel());
    const [refIds, setRefIds] = useState([]);
    const [businessLicenseFile, setBusinessLicenseFile] = useState();
    const [pharmaBusinessLicenseFile, setPharmaBusinessLicenseFile] = useState();
    const [apcLicenseFile, setAPCLicenseFile] = useState();
    const [poisonALicenseFile, setPoisonALicenseFile] = useState();
    const [isRequirePoisonLicense, setIsRequirePoisonLicense] = useState(false);
    const [isRequireAPCLicense, setIsRequireAPCLicense] = useState(false);
    const [verification, setVerification] = useState('');
    const [status, setStatus] = useState('');
    const [displayFileURL, setDisplayFileURL] = useState('');
    const [refreshForm, setRefreshForm] = useState(false);

    const closeToggle = () => {
        setDisplayFileURL('');
    }

    /**
     * Load all states in Malaysia
     */
    const loadStates = async () => {
        try {
            const allStates = filterStates(statesMYData);
            setStates(allStates);
            setBillingStates(allStates);
            setShippingStates(allStates);
        } catch (error) {
            console.warn(error);
        }
    };

    // /**
    //  * Load all cities in Viet Nam
    //  */
    // const loadCities = async () => {
    //     try {
    //         const allCities = filterCities(locationData)
    //         setCities(allCities);
    //         setBillingCities(allCities);
    //         setShippingCities(allCities);
    //     } catch (error) {
    //         console.warn(error);
    //     }
    // };

    const handleInputFormInfo = (e) => {
        const fieldName = e.target.name;
        const fieldValue = e.target.value;

        setFormCustomerData((prevState) => ({
            ...prevState,
            [fieldName]: fieldValue
        }));
    }

    const handleInputFormProfile = (e) => {
        const fieldName = e.target.name;
        const fieldValue = e.target.value;

        setFormProfile((prevState) => ({
            ...prevState,
            [fieldName]: fieldValue
        }));
    }

    const handleInputFormProfileNoSpace = (e) => {
        const fieldName = e.target.name;
        const fieldValue = e.target.value;
        validateNoSpace(fieldValue);

        setFormProfile((prevState) => ({
            ...prevState,
            [fieldName]: fieldValue.replace(/\s+/g, "")
        }));
    }

    const handleInputFormBillingNoSpace = (e) => {
        const fieldName = e.target.name;
        const fieldValue = e.target.value;
        validateNoSpace(fieldValue);

        setFormBilling((prevState) => ({
            ...prevState,
            [fieldName]: fieldValue.replace(/\s+/g, "")
        }));
    }

    const handleInputFormInfoNoSpace = (e) => {
        const fieldName = e.target.name;
        const fieldValue = e.target.value;
        validateNoSpace(fieldValue);

        setFormCustomerData((prevState) => ({
            ...prevState,
            [fieldName]: fieldValue.replace(/\s+/g, "")
        }));
    }

    const handleInputFormShippingNoSpace = (e) => {
        const fieldName = e.target.name;
        const fieldValue = e.target.value;
        validateNoSpace(fieldValue);

        setFormShipping((prevState) => ({
            ...prevState,
            [fieldName]: fieldValue.replace(/\s+/g, "")
        }));
    }

    const validateNoSpace = (fieldValue) => {
        const tmpStr = fieldValue.split(' ');

        if (tmpStr && tmpStr.length > 1) {
            showToastWarning(TextApp.getText(TextKey.not_contain_spaces));
        }
    }

    const handleChooseBusinessLicenseFile = (e) => {
        setBusinessLicenseFile(e.target.files[0]);
    }

    const handleChoosePharmaBusinessLicenseFile = (e) => {
        setPharmaBusinessLicenseFile(e.target.files[0]);
    }

    const handleChooseAPCFile = (e) => {
        setAPCLicenseFile(e.target.files[0]);
    }

    const handleChoosePoisonALicenseFile = (e) => {
        setPoisonALicenseFile(e.target.files[0]);
    }

    const handleInputFormBilling = (e) => {
        const fieldName = e.target.name;
        const fieldValue = e.target.value;

        setFormBilling((prevState) => ({
            ...prevState,
            [fieldName]: fieldValue
        }));
    }

    const handleInputFormShipping = (e) => {
        const fieldName = e.target.name;
        const fieldValue = e.target.value;

        setFormShipping((prevState) => ({
            ...prevState,
            [fieldName]: fieldValue
        }));
    }

    const chooseProfileCountry = (value) => {
        // Profile
        formProfile.locCodes = new LocCodesModel();
        formProfile.locCodes.countryCode = value;
        formProfile.city = '';
        formProfile.state = '';
        formProfile.postalCode = '';
        // Billing
        formBilling.locCodes = new LocCodesModel();
        formBilling.locCodes.countryCode = value;
        formBilling.city = '';
        formBilling.state = '';
        // Shipping
        formShipping.locCodes = new LocCodesModel();
        formShipping.locCodes.countryCode = value;
        formShipping.city = '';
        formShipping.state = '';
        formShipping.postalCode = '';
        setEnableEnterManualProfileAddress(value !== CountryCodes.VIETNAM_COUNTRY_CODE);
        chooseProfileCity('');
        chooseBillingCity('');
        chooseShippingCity('');

        if (value) {
            const countrySelected = findCountryByCode(countries, value);

            if (countrySelected) {
                formProfile.country = countrySelected.name;
                formShipping.country = countrySelected.name;
            }
        }
    };

    // const chooseShippingCountry = (value) => {
    //     formShipping.locCodes.countryCode = value;
    //     formShipping.city = '';
    //     formShipping.state = '';
    //     formShipping.postalCode = '';
    //     setEnableEnterManualShippingAddress(value !== CountryCodes.VIETNAM_COUNTRY_CODE);
    //     chooseShippingCity('');

    //     if (value) {
    //         const countrySelected = findCountryByCode(countries, value);

    //         if (countrySelected) {
    //             formShipping.country = countrySelected.name;
    //         }
    //     }
    // };

    const chooseProfileState = (value) => {
        formProfile.state = '';
        formProfile.locCodes.stateCode = value;
        const stateSelected = findStateByCode(states, value);

        if (stateSelected) {
            formProfile.state = stateSelected.NameState;
        }

        setRefreshForm(!refreshForm);
    };

    const chooseProfileCity = (value) => {
        setDistricts([]);
        setWards([]);
        formProfile.locCodes.cityCode = value;
        formProfile.locCodes.districtCode = '';
        formProfile.locCodes.wardCode = '';
        formProfile.city = ''
        formProfile.district = ''
        formProfile.ward = ''

        if (value) {
            const citySelected = findCityByCode(cities, value);

            if (citySelected) {
                formProfile.city = citySelected.NameCity;
            }

            const result = filterDistricts(locationData, value);
            setDistricts(result);
        }
    };

    const chooseProfileDistrict = (value) => {
        setWards([]);
        formProfile.locCodes.districtCode = value;
        formProfile.locCodes.wardCode = '';
        formProfile.district = ''
        formProfile.ward = ''

        if (value) {
            const districtSelected = findDistrictByCode(districts, value);;

            if (districtSelected) {
                formProfile.district = districtSelected.NameDistrict;
            }

            const result = filterWards(locationData, value);
            setWards(result);
        }
    };

    const chooseProfileWard = (value) => {
        formProfile.locCodes.wardCode = value;
        formProfile.ward = ''

        if (value) {
            const wardSelected = findWardByCode(wards, value);;

            if (wardSelected) {
                formProfile.ward = wardSelected.NameWard;
                setWards([...wards]);
            }
        }
        setRefreshForm(!refreshForm);
    };

    const chooseForensicGroup = (value) => {
        formCustomerData.userGroup = value;
        setUserGroup([...userGroup]);
    };

    const choosePrefix = (value) => {
        formCustomerData.prefix = value;
        setPrefixList([...prefixList]);
    };

    const chooseNatureOfBusiness = (value) => {
        formCustomerData.natureOfBusiness = value;
        // setRegionsGroup([]);

        // // Reset region group (customer_group)
        // let natureSelected = natureOfBusinessGroup.filter((item) => String(item.keyword) === String(value));

        // if (natureSelected && natureSelected.length > 0) {
        //     const cusGroup = natureSelected[0].customerGroup ? natureSelected[0].customerGroup : [];
        //     setRegionsGroup([...cusGroup]);
        // }

        setRefreshForm(!refreshForm);
    };

    const chooseSpecialty = (value) => {
        formCustomerData.specialty = value;
        setRefreshForm(!refreshForm);
    };

    const chooseRegionGroup = (value) => {
        formCustomerData.regionGroup = value;
        setRefreshForm(!refreshForm);
    };

    const chooseDesignationGroup = (value) => {
        formCustomerData.designation = value;
        // Require license file
        setIsRequireAPCLicense(false);
        setIsRequirePoisonLicense(false);

        switch (value) {
            case DesignationRequireLicenseFile.DOCTOR:
                setIsRequireAPCLicense(true);
                break;

            case DesignationRequireLicenseFile.PHARMACIST:
                setIsRequireAPCLicense(true);
                setIsRequirePoisonLicense(true);
                break;

            default:
                break;
        }

        setRefreshForm(!refreshForm);
    };

    // Billing info
    const chooseBillingState = (value) => {
        formBilling.locCodes.stateCode = value;
        formBilling.state = '';

        const stateSelected = findStateByCode(billingStates, value);

        if (stateSelected) {
            formBilling.state = stateSelected.NameState;
        }

        setRefreshForm(!refreshForm);
    };

    // Billing info
    const chooseBillingCity = (value) => {
        setBillingDistricts([]);
        setBillingWards([]);

        if (formBilling.locCodes == null) {
            formBilling.locCodes = new LocCodesModel();
            formBilling.locCodes.countryCode = formProfile.locCodes ? formProfile.locCodes.countryCode : '';
        }

        formBilling.locCodes.cityCode = value;
        formBilling.locCodes.districtCode = '';
        formBilling.locCodes.wardCode = '';
        formBilling.city = '';
        formBilling.district = '';
        formBilling.ward = '';

        if (value) {
            const result = filterDistricts(locationData, value);
            const citySelected = findCityByCode(billingCities, value);

            if (citySelected) {
                formBilling.city = citySelected.NameCity;
            }

            setBillingDistricts(result);
        }
    };

    const chooseBillingDistrict = (value) => {
        setBillingWards([]);
        formBilling.locCodes.districtCode = value;
        formBilling.locCodes.wardCode = '';
        formBilling.district = '';
        formBilling.ward = '';

        if (value) {
            const result = filterWards(locationData, value);
            const districtSelected = findDistrictByCode(billingDistricts, value);;

            if (districtSelected) {
                formBilling.district = districtSelected.NameDistrict;
            }

            setBillingWards(result);
        }
    };

    const chooseBillingWard = (value) => {
        formBilling.locCodes.wardCode = value;
        formBilling.ward = '';
        const wardSelected = findWardByCode(billingWards, value);

        if (wardSelected) {
            formBilling.ward = wardSelected.NameWard;
            setBillingWards([...billingWards]);
        }
        setRefreshForm(!refreshForm);
    };

    // Shipping info
    const chooseShippingState = (value) => {
        formShipping.locCodes.stateCode = value;
        formShipping.state = '';

        const stateSelected = findStateByCode(shippingStates, value);

        if (stateSelected) {
            formShipping.state = stateSelected.NameState;
        }

        setRefreshForm(!refreshForm);
    };

    // Shipping info
    const chooseShippingCity = (value) => {
        setShippingDistricts([]);
        setShippingWards([]);
        formShipping.locCodes.cityCode = value;
        formShipping.locCodes.districtCode = '';
        formShipping.locCodes.wardCode = '';
        formShipping.city = '';
        formShipping.district = '';
        formShipping.ward = '';

        if (value) {
            const result = filterDistricts(locationData, value);
            const citySelected = findCityByCode(shippingCities, value);

            if (citySelected) {
                formShipping.city = citySelected.NameCity;
            }

            setShippingDistricts(result);
        }
    };

    const chooseShippingDistrict = (value) => {
        setShippingWards([]);
        formShipping.locCodes.districtCode = value;
        formShipping.locCodes.wardCode = '';
        formShipping.district = '';
        formShipping.ward = '';

        if (value) {
            const result = filterWards(locationData, value);
            const districtSelected = findDistrictByCode(shippingDistricts, value);;

            if (districtSelected) {
                formShipping.district = districtSelected.NameDistrict;
            }

            setShippingWards(result);
        }
    };

    const chooseShippingWard = (value) => {
        formShipping.locCodes.wardCode = value;
        formShipping.ward = '';
        const wardSelected = findWardByCode(shippingWards, value);;

        if (wardSelected) {
            formShipping.ward = wardSelected.NameWard;
            setShippingWards([...shippingWards]);
        }
        setRefreshForm(!refreshForm);
    };

    const chooseBusinessLicensePlaceOfIssue = (value) => {
        formProfile.locCodes.businessLicensePOICode = value;
        const citySelected = findCityByCode(cities, value);

        if (citySelected) {
            formProfile.businessLicensePlaceOfIssue = citySelected.NameCity;
            setCities([...cities]);
        }

        setRefreshForm(!refreshForm);
    };

    const choosePharmaBusinessLicensePlaceOfIssue = (value) => {
        formProfile.locCodes.pharmaBusinessLicensePOICode = value;
        const citySelected = findCityByCode(cities, value);

        if (citySelected) {
            formProfile.pharmaBusinessLicensePlaceOfIssue = citySelected.NameCity;
            setCities([...cities]);
        }

        setRefreshForm(!refreshForm);
    };

    const onChangeDate = e => {
        // const newDate = moment(new Date(e.target.value)).format('YYYY-MM-DD');
        const fieldName = e.target.name;

        setFormProfile((prevState) => ({
            ...prevState,
            [fieldName]: e.target.value
        }));
    };

    const getBusinessLicenseFileURL = () => {
        let fileURL = '';

        if (customerDetails) {
            fileURL = customerDetails.profileInfo && customerDetails.profileInfo.businessLicenseURL ? customerDetails.profileInfo.businessLicenseURL : '';

            if (customerDetails.keyFile && customerDetails.keyFile.businessLicenseKeyFile) {
                fileURL = AssetsService.getFileURL(customerDetails.keyFile.businessLicenseKeyFile);
            }
        }

        return fileURL;
    };

    const getPharmaBusinessLicenseFileURL = () => {
        let fileURL = '';

        if (customerDetails) {
            fileURL = customerDetails.profileInfo && customerDetails.profileInfo.pharmaBusinessLicenseURL ? customerDetails.profileInfo.pharmaBusinessLicenseURL : '';

            if (customerDetails.keyFile && customerDetails.keyFile.pharmaBusinessLicenseKeyFile) {
                fileURL = AssetsService.getFileURL(customerDetails.keyFile.pharmaBusinessLicenseKeyFile);
            }
        }

        return fileURL;
    };

    const getApcFileURL = () => {
        let fileURL = '';

        if (customerDetails) {
            fileURL = customerDetails.profileInfo && customerDetails.profileInfo.apcLicenseURL ? customerDetails.profileInfo.apcLicenseURL : '';

            if (customerDetails.keyFile && customerDetails.keyFile.apcLicenseKeyFile) {
                fileURL = AssetsService.getFileURL(customerDetails.keyFile.apcLicenseKeyFile);
            }
        }

        return fileURL;
    };

    const getPoisonALicenseFileURL = () => {
        let fileURL = '';

        if (customerDetails) {
            fileURL = customerDetails.profileInfo && customerDetails.profileInfo.poisonALicenseURL ? customerDetails.profileInfo.poisonALicenseURL : '';

            if (customerDetails.keyFile && customerDetails.keyFile.poisonALicenseKeyFile) {
                fileURL = AssetsService.getFileURL(customerDetails.keyFile.poisonALicenseKeyFile);
            }
        }

        return fileURL;
    };

    const getSignedFileURL = async (url) => {
        try {
            const signedResult = url ? await AssetsService.getSignedURL(url, true) : null;

            if (signedResult) {
                if (signedResult.ext && DOCS_FILE_EXTENSION.indexOf(signedResult.ext) >= 0) {
                    var a = document.createElement('a');
                    a.href = AssetsService.getDocumentFileURL(signedResult.keyFile);
                    a.target = '_blank';
                    a.click();
                } else {
                    setDisplayFileURL(signedResult.url);
                }
            } else {
                setDisplayFileURL('');
            }
        } catch (error) {
            // console.warn(error);
        }
    };

    const loadData = async () => {
        const params = new URLSearchParams(location.search)
        const customerId = params && params.get(QueryParamsKey.CUSTOMER_ID) ? params.get(QueryParamsKey.CUSTOMER_ID) : '';

        // Load all states in MY
        loadStates();
        // Load all cities in Viet Nam
        // loadCities();
        // Load all vendors available
        try {
            const allVendors = isAdmin ? await VendorsService.findVendors({}, false, true) : [];
            setVendors(allVendors);

            // Fetch vendor's customer details
            if (customerId) {
                getVendorCustomerDetails(allVendors, customerId);
            }
        } catch (error) {
            console.warn(error);
        }
    };

    const getPhoneNumberFormatHint = () => {
        const countryCode = formProfile && formProfile.locCodes ? formProfile.locCodes.countryCode : CountryCodes.VIETNAM_COUNTRY_CODE;
        return makeMobileNumberFormatHint(countryCode);
    };

    useEffect(() => {
        if (initialized.current)
            return;

        initialized.current = true;
        // Default country / region
        // chooseProfileCountry(CountryCodes.VIETNAM_COUNTRY_CODE);
        // chooseShippingCountry(CountryCodes.VIETNAM_COUNTRY_CODE);
        // Load data
        loadData();
    }, [navigate, location]);

    /**
     * Get vendor's customer details
     * @param {*} customerId 
     */
    const getVendorCustomerDetails = async (allVendors, customerId) => {
        try {
            setLoading(true);

            await VendorsService.getVendorCustomerDetails(customerId)
                .then((response) => {
                    setLoading(false);

                    if (response) {
                        setCustomerDetails(response);

                        setVerification(response.verification);
                        setFormCustomerData(response);
                        // Business info
                        chooseNatureOfBusiness(response.natureOfBusiness ? response.natureOfBusiness : '');
                        chooseDesignationGroup(response.designation ? response.designation : '');
                        // Profile info
                        if (response.profileInfo) {
                            setFormProfile(response.profileInfo);
                            // Binding location
                            if (response.profileInfo.locCodes && (response.profileInfo.locCodes.countryCode === '' || response.profileInfo.locCodes.countryCode === CountryCodes.VIETNAM_COUNTRY_CODE)) {
                                chooseProfileCity(response.profileInfo.locCodes.cityCode);
                                chooseProfileDistrict(response.profileInfo.locCodes.districtCode);
                                chooseProfileWard(response.profileInfo.locCodes.wardCode);
                            }
                        }
                        // Shipping info
                        if (response.shippingInfo) {
                            setFormShipping(response.shippingInfo);
                            // Binding location
                            if (response.shippingInfo.locCodes && (response.shippingInfo.locCodes.countryCode === '' || response.shippingInfo.locCodes.countryCode === CountryCodes.VIETNAM_COUNTRY_CODE)) {
                                chooseShippingCity(response.shippingInfo.locCodes.cityCode);
                                chooseShippingDistrict(response.shippingInfo.locCodes.districtCode);
                                chooseShippingWard(response.shippingInfo.locCodes.wardCode);
                            }
                        }
                        // Billing info
                        if (response.billingInfo) {
                            setFormBilling(response.billingInfo);
                            // Binding location
                            if (response.billingInfo.locCodes && (response.profileInfo.locCodes && (response.profileInfo.locCodes.countryCode === '' || response.profileInfo.locCodes.countryCode === CountryCodes.VIETNAM_COUNTRY_CODE))) {
                                chooseBillingCity(response.billingInfo.locCodes.cityCode);
                                chooseBillingDistrict(response.billingInfo.locCodes.districtCode);
                                chooseBillingWard(response.billingInfo.locCodes.wardCode);
                            }
                        }
                    } else {
                        if (response && response.message) {
                            showToastError(response.message);
                        }
                    }
                });
        } catch (error) {
            setLoading(false);

            if (error && error.message) {
                showToastError(error.message);
            }
        }
    };

    /**
     * Handle submit all information to UCD
     * @param {*} e 
     * @returns 
     */
    const handleSubmitInfo = (e) => {
        e.preventDefault();

        // Validate data
        if (!validateData()) {
            showToastWarning(TextApp.getText(TextKey.requied_fields));
            return;
        }

        let unifiedCustomer = new UnifiedCustomerModel();
        let customerInfo = new CustomerInfoModel(userLogged && userLogged.vendorId ? userLogged.vendorId : VendorIdDefault);
        // Form data
        const data = new FormData();

        // if (businessLicenseFile == null || pharmaBusinessLicenseFile == null || apcLicenseFile == null) {
        //     showToastError(`Please provide the business license/pharma license certificate`);
        //     return;
        // }

        customerInfo = formCustomerData;
        customerInfo.profileInfo = formProfile;
        customerInfo.billingInfo = formBilling;
        customerInfo.shippingInfo = formShipping;
        // Format phone number: [country_code][phone_number]
        const countryCode = formProfile && formProfile.locCodes ? formProfile.locCodes.countryCode : CountryCodes.MALAYSIA_COUNTRY_CODE;
        // const profilePhoneNumber = reFormatPhoneNumber(formCustomerData.phoneNumber, countryCode);
        // customerInfo.phoneNumber = profilePhoneNumber;

        const shippingContactNumber = reFormatPhoneNumber(formShipping.contactNumber, countryCode);
        customerInfo.shippingInfo.contactNumber = shippingContactNumber;
        // Reference ID
        // unifiedCustomer.referenceCodes = [];

        if (customerDetails) {
            // Original file
            customerInfo.keyFile = customerDetails.keyFile ? customerDetails.keyFile : null;
        }

        customerInfo.verification = verification;
        // Unified customer
        unifiedCustomer.verification = verification;
        unifiedCustomer.details = customerInfo;
        unifiedCustomer.mappingData();

        // Data submit
        data.append(FormDataRequest.BUSINESS_LICENSE_FILE_KEY, businessLicenseFile);
        // data.append(FormDataRequest.PHARMA_BUSINESS_LICENSE_FILE_KEY, pharmaBusinessLicenseFile);
        data.append(FormDataRequest.APC_LICENSE_FILE_KEY, apcLicenseFile);
        data.append(FormDataRequest.POISON_A_LICENSE_FILE_KEY, poisonALicenseFile);
        data.append(FormDataRequest.DATA_KEY, JSON.stringify(unifiedCustomer));

        // Send data to UCD
        sendDataToUCD(data);
    };

    /**
     * Validate form
     * @returns
     */
    const validateData = () => {
        const error = { ...errorObj };
        const resultValidateCustomer = CustomerInfoModel.validate(formCustomerData, error, isAdmin, enableEnterManualProfileAddress);
        // Profile
        const resultValidateProfile = ProfileInfoModel.validate(formProfile, error.profileInfo);
        // Shipping address
        const resultValidateShipping = ShippingInfoModel.validate(formShipping, error.shippingInfo);
        // Billing address
        const resultValidateBilling = true; // BillingInfoModel.validate(formBilling, error.billingInfo);

        // Validate phone number and email if needed
        let validatePhoneAndEmail = true;
        const countryCode = formProfile && formProfile.locCodes ? formProfile.locCodes.countryCode : CountryCodes.MALAYSIA_COUNTRY_CODE;

        // Profile
        if (formCustomerData.phoneNumber != null && formCustomerData.phoneNumber !== '') {
            if (!validatePhoneNumber(formCustomerData.phoneNumber, countryCode, countryCode === CountryCodes.VIETNAM_COUNTRY_CODE)) {
                validatePhoneAndEmail = false;
                error.phoneNumber = TextApp.getText(TextKey.phone_number_invalid);
            }
        }
        // Shipping
        if (formShipping.contactNumber != null && formShipping.contactNumber !== '') {
            if (!validatePhoneNumber(formShipping.contactNumber, countryCode, countryCode === CountryCodes.VIETNAM_COUNTRY_CODE)) {
                validatePhoneAndEmail = false;
                error.shippingInfo.contactNumber = TextApp.getText(TextKey.phone_number_invalid);
            }
        }
        // Email
        if (formCustomerData.email != null && formCustomerData.email !== '') {
            if (!validateEmail(formCustomerData.email)) {
                validatePhoneAndEmail = false;
                error.email = TextApp.getText(TextKey.email_invalid);
            }
        }

        setErrors(error);

        return resultValidateCustomer && resultValidateProfile && resultValidateShipping && resultValidateBilling && validatePhoneAndEmail;
    }

    /**
    * Send customer info to server
    * @param {} customerInfo 
    */
    const sendDataToUCD = async (customerInfo) => {
        try {
            setLoading(true);

            await VendorsService.updateCustomer(customerDetails.id, customerInfo)
                .then((response) => {
                    setLoading(false);

                    if (response && response.success) {
                        showToastSuccess(TextApp.getText(TextKey.update_successful));
                        resetForm();
                        // navigate(-1);
                        navigate('/vendor-customers', { replace: true });
                    } else {
                        if (response && response.message) {
                            showToastError(response.message);
                        }
                    }
                });
        } catch (error) {
            setLoading(false);

            if (error && error.message) {
                showToastError(error.message);
            }
        }
    };

    const resetForm = () => {
        let customerInfo = new CustomerInfoModel(userLogged && userLogged.vendorId ? userLogged.vendorId : VendorIdDefault);

        setFormCustomerData(customerInfo);
        setFormProfile(customerInfo.profileInfo);
        setFormBilling(customerInfo.billingInfo);
        setFormShipping(customerInfo.shippingInfo);
    };

    return (
        <>
            <MainPagetitle mainTitle="Customer Management" pageTitle="Profile" parentTitle="Home" />
            <div className="container-fluid">
                {/* <ToastContainer /> */}
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body p-3">
                            <div className="d-flex align-items-center justify-content-between mb-4">
                                <h4 className="heading mb-0">Edit Customer</h4>
                                {/* <div className="d-flex align-items-center">
                                    <Link to={"#"} className="btn btn-primary btn-sm ms-2" data-bs-toggle="offcanvas" onClick={() => { }}>
                                        + Import Customer
                                    </Link>
                                </div> */}
                            </div>
                            <div className="dataTables_wrapper no-footer">
                                <Row>
                                    <Col xl={12}>
                                        <Tab.Container defaultActiveKey="Preview">
                                            <Card name="default-tab" className="dz-card">
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="Preview">
                                                        <Card.Body>
                                                            {/* <!-- Nav tabs --> */}
                                                            <div className="default-tab">
                                                                <Tab.Container defaultActiveKey={tabsCustomerInfo.length > 0 ? tabsCustomerInfo[0].key : ''}>
                                                                    <Nav as="ul" className="nav-tabs">
                                                                        {tabsCustomerInfo.map((data, i) => (
                                                                            <Nav.Item as="li" key={i}>
                                                                                <Nav.Link eventKey={data.key}>
                                                                                    <i className={`la la-${data.icon} me-2`} />
                                                                                    {data.name}
                                                                                </Nav.Link>
                                                                            </Nav.Item>
                                                                        ))}
                                                                    </Nav>
                                                                    <Tab.Content className="pt-4">
                                                                        {tabsCustomerInfo.map((data, i) => (
                                                                            <Tab.Pane eventKey={data.key} key={i}>
                                                                                {/* Basic Information */}
                                                                                {data.key === CustomerTabKeys.BASIC_INFORMATION ? (
                                                                                    <form id="#basicInfoForm">
                                                                                        <div className="form-validation">
                                                                                            <div className="row">
                                                                                                <div className="col-xl-6">
                                                                                                    <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="val-customer-code">Customer Code<span className="text-danger">*</span></label>
                                                                                                        <div className="col-lg-7">
                                                                                                            <input type="text" className="form-control" id="val-customer-code" name="customerCode" value={formCustomerData.customerCode} onChange={handleInputFormInfoNoSpace} placeholder="Enter customer code" />
                                                                                                            {errors.customerCode && <div className="text-danger fs-12">{errors.customerCode}</div>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="val-prefix">Prefix</label>
                                                                                                        <div className="col-lg-7">
                                                                                                            <select className="form-control" id="val-prefix" name="val-prefix" value={formCustomerData.prefix} onChange={(e) => choosePrefix(e.target.value)}>
                                                                                                                <option value="">Please select</option>
                                                                                                                {prefixList.map((group, i) => { return (<option value={group.keyword} key={i}>{group.name}</option>); })}
                                                                                                            </select>
                                                                                                            {errors.prefix && <div className="text-danger fs-12">{errors.prefix}</div>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="val-healthcare-full-name">Healthcare Professional Full Name<span className="text-danger">*</span></label>
                                                                                                        <div className="col-lg-7">
                                                                                                            <input type="text" className="form-control" id="val-healthcare-full-name" name="healthcareFullName" value={formCustomerData.healthcareFullName} onChange={handleInputFormInfo} required />
                                                                                                            {errors.healthcareFullName && <div className="text-danger fs-12">{errors.healthcareFullName}</div>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="representative-name">Business Registration Name<span className="text-danger">*</span></label>
                                                                                                        <div className="col-lg-7">
                                                                                                            <input type="text" className="form-control" id="representative-name" name="representativeName" value={formCustomerData.representativeName} onChange={handleInputFormInfo} required />
                                                                                                            {errors.representativeName && <div className="text-danger fs-12">{errors.representativeName}</div>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="val-company-name">Company Name<span className="text-danger">*</span></label>
                                                                                                        <div className="col-lg-7">
                                                                                                            <input type="text" className="form-control" id="val-company-name" name="companyName" value={formProfile.companyName} onChange={handleInputFormProfile} placeholder="" required />
                                                                                                            {errors.profileInfo.companyName && <div className="text-danger fs-12">{errors.profileInfo.companyName}</div>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="val-nature-of-business-group">Specialty<span className="text-danger">*</span></label>
                                                                                                        <div className="col-lg-7">
                                                                                                            <select className="form-control" id="val-nature-of-business-group" name="val-nature-of-business-group" value={formCustomerData.specialty} onChange={(e) => chooseSpecialty(e.target.value)} required>
                                                                                                                <option value="">Please select</option>
                                                                                                                {specialtyList.map((group, i) => { return (<option value={group.keyword} key={i}>{group.name}</option>); })}
                                                                                                            </select>
                                                                                                            {errors.specialty && <div className="text-danger fs-12">{errors.specialty}</div>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="val-nature-of-business-group">Nature of Business<span className="text-danger">*</span></label>
                                                                                                        <div className="col-lg-7">
                                                                                                            <select className="form-control" id="val-nature-of-business-group" name="val-nature-of-business-group" value={formCustomerData.natureOfBusiness} onChange={(e) => chooseNatureOfBusiness(e.target.value)} required>
                                                                                                                <option value="">Please select</option>
                                                                                                                {natureOfBusinessGroup.map((group, i) => { return (<option value={group.keyword} key={i}>{group.name}</option>); })}
                                                                                                            </select>
                                                                                                            {errors.natureOfBusiness && <div className="text-danger fs-12">{errors.natureOfBusiness}</div>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {/* <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="val-region-group">Customer Group<span className="text-danger">*</span></label>
                                                                                                        <div className="col-lg-7">
                                                                                                            {oldUCInfo && hasDifferent(formCustomerData.regionGroup, oldUCInfo.regionGroup) && <div className="text-warning fs-12">{NatureOfBusinessDefault.GetName(oldUCInfo.regionGroup, regionsGroup)}</div>}
                                                                                                            <select className="form-control" id="val-region-group" name="val-region-group" value={formCustomerData.regionGroup} onChange={(e) => chooseRegionGroup(e.target.value)} required>
                                                                                                                <option value="">Please select</option>
                                                                                                                {regionsGroup.map((group, i) => { return (<option value={group.keyword} key={i}>{group.name}</option>); })}
                                                                                                            </select>
                                                                                                            {errors.regionGroup && <div className="text-danger fs-12">{errors.regionGroup}</div>}
                                                                                                        </div>
                                                                                                    </div> */}
                                                                                                    <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="val-designation-group">Designation<span className="text-danger">*</span></label>
                                                                                                        <div className="col-lg-7">
                                                                                                            <select className="form-control" id="val-designation-group" name="val-designation-group" value={formCustomerData.designation} onChange={(e) => chooseDesignationGroup(e.target.value)} required>
                                                                                                                <option value="">Please select</option>
                                                                                                                {RoleOfSubUserDefault.ListAvailable().map((group, i) => { return (<option value={group.keyword} key={i}>{group.name}</option>); })}
                                                                                                            </select>
                                                                                                            {errors.designation && <div className="text-danger fs-12">{errors.designation}</div>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="val-user-group">User Group</label>
                                                                                                        <div className="col-lg-7">
                                                                                                            {/* <select className="form-control" id="val-user-group" name="val-user-group" value={formCustomerData.userGroup} onChange={(e) => chooseForensicGroup(e.target.value)}>
                                                                                                                <option value="">Please select</option>
                                                                                                                {userGroup.map((group, i) => { return (<option value={group.keyword} key={i}>{group.name}</option>); })}
                                                                                                            </select> */}
                                                                                                            <input type="text" className="form-control" id="val-user-group" name="userGroup" value={formCustomerData.userGroup} onChange={handleInputFormInfo} />
                                                                                                            {errors.userGroup && <div className="text-danger fs-12">{errors.userGroup}</div>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-xl-6">
                                                                                                    <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="val-mmc-no">MMC Number<span className="text-danger">*</span></label>
                                                                                                        <div className="col-lg-7">
                                                                                                            <input type="text" className="form-control" id="val-mmc-no" name="mmcNo" value={formProfile.mmcNo} onChange={handleInputFormProfileNoSpace} placeholder="" required />
                                                                                                            {errors.profileInfo.mmcNo && <div className="text-danger fs-12">{errors.profileInfo.mmcNo}</div>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="val-ssm-no">SSM Number<span className="text-danger">*</span></label>
                                                                                                        <div className="col-lg-7">
                                                                                                            <input type="text" className="form-control" id="val-ssm-no" name="ssmNo" value={formProfile.ssmNo} onChange={handleInputFormProfileNoSpace} placeholder="" required />
                                                                                                            {errors.profileInfo.ssmNo && <div className="text-danger fs-12">{errors.profileInfo.ssmNo}</div>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="val-tin-no">TIN Number<span className="text-danger">*</span></label>
                                                                                                        <div className="col-lg-7">
                                                                                                            <input type="text" className="form-control" id="val-tin-no" name="tinNo" value={formProfile.tinNo} onChange={handleInputFormProfileNoSpace} placeholder="" required />
                                                                                                            {errors.profileInfo.tinNo && <div className="text-danger fs-12">{errors.profileInfo.tinNo}</div>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="val-msic-no">MSIC Code<span className="text-danger">*</span></label>
                                                                                                        <div className="col-lg-7">
                                                                                                            <input type="text" className="form-control" id="val-msic-no" name="msicNo" value={formProfile.msicNo} onChange={handleInputFormProfileNoSpace} placeholder="" required />
                                                                                                            {errors.profileInfo.msicNo && <div className="text-danger fs-12">{errors.profileInfo.msicNo}</div>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="val-soldToCustomerCode">Sold to Customer Code<span className="text-danger">*</span></label>
                                                                                                        <div className="col-lg-7">
                                                                                                            <input type="text" className="form-control" id="val-soldToCustomerCode" name="soldToCustomerCode" value={formProfile.soldToCustomerCode} onChange={handleInputFormProfileNoSpace} placeholder="" required />
                                                                                                            {errors.profileInfo.soldToCustomerCode && <div className="text-danger fs-12">{errors.profileInfo.soldToCustomerCode}</div>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="val-shipToCustomerCode">Ship to Customer Code<span className="text-danger">*</span></label>
                                                                                                        <div className="col-lg-7">
                                                                                                            <input type="text" className="form-control" id="val-shipToCustomerCode" name="shipToCustomerCode" value={formProfile.shipToCustomerCode} onChange={handleInputFormProfileNoSpace} placeholder="" required />
                                                                                                            {errors.profileInfo.shipToCustomerCode && <div className="text-danger fs-12">{errors.profileInfo.shipToCustomerCode}</div>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="val-business-license-no">SSM Form 9 / Form B<span className="text-danger">*</span></label>
                                                                                                        <div className="col-lg-7">
                                                                                                            <input className="form-control" type="file" id="val-business-license-image" value={formProfile.businessLicenseFile} onChange={handleChooseBusinessLicenseFile} accept={ACCEPT_BUSINESS_LICENSE_FILE} required />
                                                                                                            <div className="mt-2">
                                                                                                                {getBusinessLicenseFileURL() && <>
                                                                                                                    <span className='text-info fs-13' onClick={() => getSignedFileURL(getBusinessLicenseFileURL())}><i className="fa fa-eye me-1"></i>File</span>
                                                                                                                </>
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {isRequireAPCLicense && <>
                                                                                                        <div className="form-group mb-3 row">
                                                                                                            <label className="col-lg-4 col-form-label" htmlFor="val-apc-image">Annual Practicing Certificate<br />(APC)<span className="text-danger">*</span></label>
                                                                                                            <div className="col-lg-7">
                                                                                                                <input className="form-control" type="file" id="val-apc-image" name="apcLicenseFile" onChange={handleChooseAPCFile} accept={ACCEPT_BUSINESS_LICENSE_FILE} />
                                                                                                                {getApcFileURL() && (<div className="mt-2">
                                                                                                                    <span className='text-info fs-13' onClick={() => getSignedFileURL(getApcFileURL())}><i className="fa fa-eye me-1"></i>File</span>
                                                                                                                </div>)}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </>}
                                                                                                    {isRequirePoisonLicense && <>
                                                                                                        <div className="form-group mb-3 row">
                                                                                                            <label className="col-lg-4 col-form-label" htmlFor="val-poison-a-license-image">Poison A License<span className="text-danger">*</span></label>
                                                                                                            <div className="col-lg-7">
                                                                                                                <input className="form-control" type="file" id="val-poison-a-license-image" name="poisonALicenseFile" onChange={handleChoosePoisonALicenseFile} accept={ACCEPT_BUSINESS_LICENSE_FILE} />
                                                                                                                {getPoisonALicenseFileURL() && (<div className="mt-2">
                                                                                                                    <span className='text-info fs-13' onClick={() => getSignedFileURL(getPoisonALicenseFileURL())}><i className="fa fa-eye me-1"></i>File</span>
                                                                                                                </div>)}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </>}
                                                                                                    <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label text-danger" htmlFor="val-verification">Verification</label>
                                                                                                        <div className="col-lg-7">
                                                                                                            {/* <input type="text" className="form-control" id="val-verification" name="val-verification" value={verification} disabled/> */}
                                                                                                            <select className="form-control text-danger bg-disabled" id="val-verification" name="val-verification" value={verification} /* onChange={(e) => chooseVerification(e.target.value)} */ disabled>
                                                                                                                <option value="">N/A</option>
                                                                                                                {VerificationStatusList.map((s, i) => {
                                                                                                                    return (<option value={s.key} key={i}>{s.title}</option>);
                                                                                                                })}
                                                                                                            </select>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {/* {verification === VerificationStatus.APPROVED && (<div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label text-danger" htmlFor="val-status">Status</label>
                                                                                                        <div className="col-lg-7">
                                                                                                            <select className="form-control" id="val-status" name="val-status" value={status} onChange={(e) => setStatus(e.target.value)}>
                                                                                                                <option value="">Please select</option>
                                                                                                                {UserStatusList.map((s, i) => {
                                                                                                                    return (<option value={s.key} key={i}>{s.title}</option>);
                                                                                                                })}
                                                                                                            </select>
                                                                                                        </div>
                                                                                                    </div>)} */}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </form>
                                                                                ) : ''}
                                                                                {/* Segments for the each tab */}
                                                                                {data.segments && data.segments.length > 0 ? <>
                                                                                    {data.segments.map((seg, segInd) => (
                                                                                        <div key={seg.key}>
                                                                                            <label className="col-form-label text-menu-label fs-6 pb-0 mt-4" htmlFor="">{seg.name}</label>
                                                                                            <hr />
                                                                                            {seg.key === CustomerTabKeys.BUSINESS_ADDRESS ? (
                                                                                                <form id="#businessAddressForm">
                                                                                                    <div className='form-validation'>
                                                                                                        <div className='row'>
                                                                                                            <div className='col-xl-6'>
                                                                                                                <div className="form-group mb-3 row">
                                                                                                                    <label className="col-lg-4 col-form-label" htmlFor="val-email">Email<span className="text-danger">*</span></label>
                                                                                                                    <div className="col-lg-7">
                                                                                                                        <input type="email" className="form-control" id="email" name="email" value={formCustomerData.email ? formCustomerData.email : ''} onChange={handleInputFormInfoNoSpace} placeholder={makeEmailFormatHint()} />
                                                                                                                        {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="form-group mb-3 row">
                                                                                                                    <label className="col-lg-4 col-form-label" htmlFor="val-phone">Contact<span className="text-danger">*</span></label>
                                                                                                                    <div className="col-lg-7">
                                                                                                                        <input type="tel" className="form-control" id="phone-number" name="phoneNumber" value={formCustomerData.phoneNumber} onChange={handleInputFormInfoNoSpace} placeholder={getPhoneNumberFormatHint()} />
                                                                                                                        {errors.phoneNumber && <div className="text-danger fs-12">{errors.phoneNumber}</div>}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="form-group mb-3 row">
                                                                                                                    <label className="col-lg-4 col-form-label" htmlFor="val-profile-country">Country<span className="text-danger">*</span></label>
                                                                                                                    <div className="col-lg-7">
                                                                                                                        <select className="form-control" id="val-profile-country" name="val-profile-country" value={formProfile.locCodes.countryCode} onChange={(e) => chooseProfileCountry(e.target.value)} required>
                                                                                                                            {/* <option value="">Please select</option> */}
                                                                                                                            {countries.length > 0 && (
                                                                                                                                countries.map((country, i) => {
                                                                                                                                    return (<option value={country.code} key={i}>{country.name}</option>);
                                                                                                                                })
                                                                                                                            )}
                                                                                                                        </select>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                {/* <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="val-region-group">Region Group<span className="text-danger">*</span></label>
                                                                                                        <div className="col-lg-7">
                                                                                                            {oldUCInfo && hasDifferent(formCustomerData.regionGroup, oldUCInfo.regionGroup) && <div className="text-warning fs-12">{RegionsGroupDefault.GetName(oldUCInfo.regionGroup)}</div>}
                                                                                                            <select className="form-control" id="val-region-group" name="val-region-group" value={formCustomerData.regionGroup} onChange={(e) => chooseRegionGroup(e.target.value)} required>
                                                                                                                <option value="">Please select</option>
                                                                                                                {regionsGroup.map((group, i) => { return (<option value={group.keyword} key={i}>{group.name}</option>); })}
                                                                                                            </select>
                                                                                                            {errors.regionGroup && <div className="text-danger fs-12">{errors.regionGroup}</div>}
                                                                                                        </div>
                                                                                                    </div> */}
                                                                                                            </div>
                                                                                                            <div className="col-xl-6">
                                                                                                                <div className="form-group mb-3 row">
                                                                                                                    <label className="col-lg-4 col-form-label" htmlFor="profile-address">Address<span className="text-danger">*</span></label>
                                                                                                                    <div className='col-lg-7'>
                                                                                                                        <div className="mb-2">
                                                                                                                            <input type="text" className="form-control" id="profile-address" name="address" value={formProfile.address} onChange={handleInputFormProfile} placeholder="" required />
                                                                                                                            {errors.profileInfo.address && <div className="text-danger fs-12">{errors.profileInfo.address}</div>}
                                                                                                                            <label className="col-lg-4 col-form-label mt-2" htmlFor="profile-address-2">Address Line 2</label>
                                                                                                                            <input type="text" className="form-control" id="profile-address-2" name="address2" value={formProfile.address2 ? formProfile.address2 : ''} onChange={handleInputFormProfile} placeholder="" />
                                                                                                                        </div>
                                                                                                                        {enableEnterManualProfileAddress ? (
                                                                                                                            <div>
                                                                                                                                <label className="col-form-label" htmlFor="val-profile-city">City<span className="text-danger">*</span></label>
                                                                                                                                <input type="text" className="form-control" id='profile-city' name="city" value={formProfile.city} onChange={handleInputFormProfile} placeholder="" />
                                                                                                                                {errors.profileInfo.city && <div className="text-danger fs-12">{errors.profileInfo.city}</div>}
                                                                                                                                <label className="col-form-label mt-2" htmlFor="val-shipping-state">State<span className="text-danger">*</span></label>
                                                                                                                                <select className="form-control" value={formProfile.locCodes.stateCode} onChange={(e) => chooseProfileState(e.target.value)} required>
                                                                                                                                    <option value="">Please select</option>
                                                                                                                                    {states.length > 0 && (
                                                                                                                                        states.map((st, i) => {
                                                                                                                                            return (<option value={st.CodeState} key={i}>{st.NameState}</option>);
                                                                                                                                        })
                                                                                                                                    )}
                                                                                                                                </select>
                                                                                                                                {errors.profileInfo.state && <div className="text-danger fs-12">{errors.profileInfo.state}</div>}
                                                                                                                                <div className='mt-2'>
                                                                                                                                    <label className="col-form-label" htmlFor="val-shipping-postal-code">Zip/Postal Code<span className="text-danger">*</span></label>
                                                                                                                                    <input type="text" className="form-control" id='profile-postal-code' name="postalCode" value={formProfile.postalCode} onChange={handleInputFormProfile} placeholder="" />
                                                                                                                                    {errors.profileInfo.postalCode && <div className="text-danger fs-12">{errors.profileInfo.postalCode}</div>}
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        ) : (
                                                                                                                            <div>
                                                                                                                                <label className="col-form-label" htmlFor="val-profile-city">City<span className="text-danger">*</span></label>
                                                                                                                                <div className="mb-2" >
                                                                                                                                    <select className="form-control" value={formProfile.locCodes.cityCode} onChange={(e) => chooseProfileCity(e.target.value)} required>
                                                                                                                                        <option value="">Please select</option>
                                                                                                                                        {cities.length > 0 && (
                                                                                                                                            cities.map((city, i) => {
                                                                                                                                                return (<option value={city.CodeCity} key={i}>{city.NameCity}</option>);
                                                                                                                                            })
                                                                                                                                        )}
                                                                                                                                    </select>
                                                                                                                                    {errors.profileInfo.city && <div className="text-danger fs-12">{errors.profileInfo.city}</div>}
                                                                                                                                </div>
                                                                                                                                <label className="col-form-label" htmlFor="val-profile-district">District</label>
                                                                                                                                <select className="form-control mb-2" value={formProfile.locCodes.districtCode} onChange={(e) => chooseProfileDistrict(e.target.value)}>
                                                                                                                                    <option value="">Please select</option>
                                                                                                                                    {districts.length > 0 && (
                                                                                                                                        (districts.map((dis, i) => {
                                                                                                                                            return (<option value={dis.CodeDistrict} key={i}>{formatDistrictWard(dis.NameDistrict, LocationFormat.DISTRICT_NUMBER_PREFIX_VN)}</option>);
                                                                                                                                        }))
                                                                                                                                    )}
                                                                                                                                </select>
                                                                                                                                <label className="col-form-label" htmlFor="val-email">Ward</label>
                                                                                                                                <select className="form-control" value={formProfile.locCodes.wardCode} onChange={(e) => chooseProfileWard(e.target.value)}>
                                                                                                                                    <option value="">Please select</option>
                                                                                                                                    {wards.length > 0 && (
                                                                                                                                        (wards.map((ward, i) => {
                                                                                                                                            return (<option value={ward.CodeWard} key={i}>{formatDistrictWard(ward.NameWard, LocationFormat.WARD_NUMBER_PREFIX_VN)}</option>);
                                                                                                                                        }))
                                                                                                                                    )}
                                                                                                                                </select>
                                                                                                                            </div>
                                                                                                                        )}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </form>
                                                                                            ) : ''}
                                                                                            {/* Billing Address */}
                                                                                            {seg.key === CustomerTabKeys.BILLING_ADDRESS ? (
                                                                                                <form id="#billingForm">
                                                                                                    <div className="form-validation">
                                                                                                        <div className="row">
                                                                                                            <div className="col-xl-6">
                                                                                                                <div className="form-group mb-3 row">
                                                                                                                    <label className="col-lg-4 col-form-label" htmlFor="val-billing-name">Name<span className="text-danger">*</span></label>
                                                                                                                    <div className="col-lg-7">
                                                                                                                        <input type="text" className="form-control" id="val-billing-name" name="name" value={formBilling.name} onChange={handleInputFormBilling} placeholder="" required />
                                                                                                                        {errors.billingInfo.name && <div className="text-danger fs-12">{errors.billingInfo.name}</div>}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="form-group mb-3 row">
                                                                                                                    <label className="col-lg-4 col-form-label" htmlFor="val-billing-tax-id">Tax ID<span className="text-danger">*</span></label>
                                                                                                                    <div className="col-lg-7">
                                                                                                                        <input type="text" className="form-control" id="val-billing-tax-id" name="taxCode" value={formBilling.taxCode} onChange={handleInputFormBillingNoSpace} placeholder="" required />
                                                                                                                        {errors.billingInfo.taxCode && <div className="text-danger fs-12">{errors.billingInfo.taxCode}</div>}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="col-xl-6">
                                                                                                                <div className="form-group mb-3 row">
                                                                                                                    <label className="col-lg-4 col-form-label" htmlFor="val-billing-address">Address<span className="text-danger">*</span></label>
                                                                                                                    <div className="col-lg-7">
                                                                                                                        <input type="text" className="form-control" id="val-billing-address" name="address" value={formBilling.address} onChange={handleInputFormBilling} placeholder="" required />
                                                                                                                        {errors.billingInfo.address && <div className="text-danger fs-12">{errors.billingInfo.address}</div>}
                                                                                                                        <label className="col-lg-4 col-form-label mt-2" htmlFor="val-billing-address-2">Address Line 2</label>
                                                                                                                        <input type="text" className="form-control" id="val-billing-address-2" name="address2" value={formBilling.address2 ? formBilling.address2 : ''} onChange={handleInputFormBilling} placeholder="" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                {enableEnterManualProfileAddress ? (
                                                                                                                    <div>
                                                                                                                        <div className="form-group mb-3 row">
                                                                                                                            <label className="col-lg-4 col-form-label" htmlFor="val-billing-city">City<span className="text-danger">*</span></label>
                                                                                                                            <div className="col-lg-7">
                                                                                                                                <input type="text" className="form-control" id="val-billing-city" name="city" value={formBilling && formBilling.city ? formBilling.city : ''} onChange={handleInputFormBilling} placeholder="" />
                                                                                                                                {errors.billingInfo.city && <div className="text-danger fs-12">{errors.billingInfo.city}</div>}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="form-group mb-3 row">
                                                                                                                            <label className="col-lg-4 col-form-label" htmlFor="val-billing-state">State<span className="text-danger">*</span></label>
                                                                                                                            <div className="col-lg-7">
                                                                                                                                <select className="form-control" value={formBilling.locCodes.stateCode} onChange={(e) => chooseBillingState(e.target.value)} required>
                                                                                                                                    <option value="">Please select</option>
                                                                                                                                    {billingStates.length > 0 && (
                                                                                                                                        billingStates.map((st, i) => {
                                                                                                                                            return (<option value={st.CodeState} key={i}>{st.NameState}</option>);
                                                                                                                                        })
                                                                                                                                    )}
                                                                                                                                </select>
                                                                                                                                {errors.billingInfo.state && <div className="text-danger fs-12">{errors.billingInfo.state}</div>}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="form-group mb-3 row">
                                                                                                                            <label className="col-lg-4 col-form-label" htmlFor="val-billing-postal-code">Zip/Postal Code<span className="text-danger">*</span></label>
                                                                                                                            <div className="col-lg-7">
                                                                                                                                <input type="text" className="form-control" id="val-billing-postal-code" name="postalCode" value={formBilling.postalCode ? formBilling.postalCode : ''} onChange={handleInputFormBilling} placeholder="" />
                                                                                                                                {errors.billingInfo.postalCode && <div className="text-danger fs-12">{errors.billingInfo.postalCode}</div>}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                ) : (
                                                                                                                    <div>
                                                                                                                        <div className="form-group mb-3 row">
                                                                                                                            <label className="col-lg-4 col-form-label" htmlFor="val-billing-city">City<span className="text-danger">*</span></label>
                                                                                                                            <div className="col-lg-7">
                                                                                                                                <select className="form-control" value={formBilling.locCodes && formBilling.locCodes.cityCode ? formBilling.locCodes.cityCode : ''} onChange={(e) => chooseBillingCity(e.target.value)} required>
                                                                                                                                    <option value="">Please select</option>
                                                                                                                                    {billingCities.length > 0 && (
                                                                                                                                        billingCities.map((city, i) => {
                                                                                                                                            return (<option value={city.CodeCity} key={i}>{city.NameCity}</option>);
                                                                                                                                        })
                                                                                                                                    )}
                                                                                                                                </select>
                                                                                                                                {errors.billingInfo.city && <div className="text-danger fs-12">{errors.billingInfo.city}</div>}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="form-group mb-3 row">
                                                                                                                            <label className="col-lg-4 col-form-label" htmlFor="val-billing-district">District</label>
                                                                                                                            <div className="col-lg-7">
                                                                                                                                <select className="form-control" value={formBilling.locCodes && formBilling.locCodes.districtCode ? formBilling.locCodes.districtCode : ''} onChange={(e) => chooseBillingDistrict(e.target.value)}>
                                                                                                                                    <option value="">Please select</option>
                                                                                                                                    {billingDistricts.length > 0 && (
                                                                                                                                        (billingDistricts.map((dis, i) => {
                                                                                                                                            return (<option value={dis.CodeDistrict} key={i}>{formatDistrictWard(dis.NameDistrict, LocationFormat.DISTRICT_NUMBER_PREFIX_VN)}</option>);
                                                                                                                                        }))
                                                                                                                                    )}
                                                                                                                                </select>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="form-group mb-3 row">
                                                                                                                            <label className="col-lg-4 col-form-label" htmlFor="val-billing-ward">Ward</label>
                                                                                                                            <div className="col-lg-7">
                                                                                                                                <select className="form-control" value={formBilling.locCodes && formBilling.locCodes.wardCode ? formBilling.locCodes.wardCode : ''} onChange={(e) => chooseBillingWard(e.target.value)}>
                                                                                                                                    <option value="">Please select</option>
                                                                                                                                    {billingWards.length > 0 && (
                                                                                                                                        (billingWards.map((ward, i) => {
                                                                                                                                            return (<option value={ward.CodeWard} key={i}>{formatDistrictWard(ward.NameWard, LocationFormat.WARD_NUMBER_PREFIX_VN)}</option>);
                                                                                                                                        }))
                                                                                                                                    )}
                                                                                                                                </select>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                )}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </form>
                                                                                            ) : ''}
                                                                                            {/* Shipping Address*/}
                                                                                            {seg.key === CustomerTabKeys.SHIPPING_ADDRESS ? (
                                                                                                <form id="#shippingForm">
                                                                                                    <div className="form-validation">
                                                                                                        <div className="row">
                                                                                                            <div className="col-xl-6">
                                                                                                                <div className="form-group mb-3 row">
                                                                                                                    <label className="col-lg-4 col-form-label" htmlFor="val-shipping-contact-name">Contact Name<span className="text-danger">*</span></label>
                                                                                                                    <div className="col-lg-7">
                                                                                                                        <input type="text" className="form-control" id="val-shipping-contact-name" name="contactName" value={formShipping.contactName} onChange={handleInputFormShipping} placeholder="" required />
                                                                                                                        {errors.shippingInfo.contactName && <div className="text-danger fs-12">{errors.shippingInfo.contactName}</div>}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="form-group mb-3 row">
                                                                                                                    <label className="col-lg-4 col-form-label" htmlFor="val-shipping-contact-number">Contact Number<span className="text-danger">*</span></label>
                                                                                                                    <div className="col-lg-7">
                                                                                                                        <input type="tel" className="form-control" id="val-shipping-contact-number" name="contactNumber" value={formShipping.contactNumber} onChange={handleInputFormShippingNoSpace} placeholder={getPhoneNumberFormatHint()} required />
                                                                                                                        {errors.shippingInfo.contactNumber && <div className="text-danger fs-12">{errors.shippingInfo.contactNumber}</div>}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                {/* <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="val-shipping-country">Country<span className="text-danger">*</span></label>
                                                                                                        <div className="col-lg-7">
                                                                                                            <select className="form-control" id="val-shipping-country" name="val-shipping-country" onChange={(e) => chooseShippingCountry(e.target.value)} required>
                                                                                                                {countries.length > 0 && (
                                                                                                                    countries.map((country, i) => {
                                                                                                                        return (<option value={country.code} key={i}>{country.name}</option>);
                                                                                                                    })
                                                                                                                )}
                                                                                                            </select>
                                                                                                        </div>
                                                                                                    </div> */}
                                                                                                            </div>
                                                                                                            <div className="col-xl-6">
                                                                                                                <div className="form-group mb-3 row">
                                                                                                                    <label className="col-lg-4 col-form-label" htmlFor="val-shipping-address">Address<span className="text-danger">*</span></label>
                                                                                                                    <div className="col-lg-7">
                                                                                                                        <input type="text" className="form-control" id="val-shipping-address" name="address" value={formShipping.address} onChange={handleInputFormShipping} placeholder="" required />
                                                                                                                        {errors.shippingInfo.address && <div className="text-danger fs-12">{errors.shippingInfo.address}</div>}
                                                                                                                        <label className="col-lg-4 col-form-label mt-2" htmlFor="val-shipping-address">Address Line 2</label>
                                                                                                                        <input type="text" className="form-control" id="val-shipping-address-2" name="address2" value={formShipping.address2 ? formShipping.address2 : ''} onChange={handleInputFormShipping} placeholder="" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                {enableEnterManualProfileAddress ? (
                                                                                                                    <div>
                                                                                                                        <div className="form-group mb-3 row">
                                                                                                                            <label className="col-lg-4 col-form-label" htmlFor="val-shipping-city">City<span className="text-danger">*</span></label>
                                                                                                                            <div className="col-lg-7">
                                                                                                                                <input type="text" className="form-control" id="val-shipping-city" name="city" value={formShipping.city} onChange={handleInputFormShipping} placeholder="" />
                                                                                                                                {errors.shippingInfo.city && <div className="text-danger fs-12">{errors.shippingInfo.city}</div>}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="form-group mb-3 row">
                                                                                                                            <label className="col-lg-4 col-form-label" htmlFor="val-shipping-state">State<span className="text-danger">*</span></label>
                                                                                                                            <div className="col-lg-7">
                                                                                                                                <select className="form-control" value={formShipping.locCodes.stateCode} onChange={(e) => chooseShippingState(e.target.value)} required>
                                                                                                                                    <option value="">Please select</option>
                                                                                                                                    {shippingStates.length > 0 && (
                                                                                                                                        shippingStates.map((st, i) => {
                                                                                                                                            return (<option value={st.CodeState} key={i}>{st.NameState}</option>);
                                                                                                                                        })
                                                                                                                                    )}
                                                                                                                                </select>
                                                                                                                                {errors.shippingInfo.state && <div className="text-danger fs-12">{errors.shippingInfo.state}</div>}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="form-group mb-3 row">
                                                                                                                            <label className="col-lg-4 col-form-label" htmlFor="val-shipping-postal-code">Zip/Postal Code<span className="text-danger">*</span></label>
                                                                                                                            <div className="col-lg-7">
                                                                                                                                <input type="text" className="form-control" id="val-shipping-postal-code" name="postalCode" value={formShipping.postalCode ? formShipping.postalCode : ''} onChange={handleInputFormShipping} placeholder="" />
                                                                                                                                {errors.shippingInfo.postalCode && <div className="text-danger fs-12">{errors.shippingInfo.postalCode}</div>}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                ) : (
                                                                                                                    <div>
                                                                                                                        <div className="form-group mb-3 row">
                                                                                                                            <label className="col-lg-4 col-form-label" htmlFor="val-shipping-city">City<span className="text-danger">*</span></label>
                                                                                                                            <div className="col-lg-7">
                                                                                                                                <select className="form-control" value={formShipping.locCodes.cityCode} onChange={(e) => chooseShippingCity(e.target.value)} required>
                                                                                                                                    <option value="">Please select</option>
                                                                                                                                    {shippingCities.length > 0 && (
                                                                                                                                        shippingCities.map((city, i) => {
                                                                                                                                            return (<option value={city.CodeCity} key={i}>{city.NameCity}</option>);
                                                                                                                                        })
                                                                                                                                    )}
                                                                                                                                </select>
                                                                                                                                {errors.shippingInfo.city && <div className="text-danger fs-12">{errors.shippingInfo.city}</div>}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="form-group mb-3 row">
                                                                                                                            <label className="col-lg-4 col-form-label" htmlFor="val-shipping-district">District<span className="text-danger">*</span></label>
                                                                                                                            <div className="col-lg-7">
                                                                                                                                <select className="form-control" value={formShipping.locCodes.districtCode} onChange={(e) => chooseShippingDistrict(e.target.value)}>
                                                                                                                                    <option value="">Please select</option>
                                                                                                                                    {shippingDistricts.length > 0 && (
                                                                                                                                        (shippingDistricts.map((dis, i) => {
                                                                                                                                            return (<option value={dis.CodeDistrict} key={i}>{formatDistrictWard(dis.NameDistrict, LocationFormat.DISTRICT_NUMBER_PREFIX_VN)}</option>);
                                                                                                                                        }))
                                                                                                                                    )}
                                                                                                                                </select>
                                                                                                                                {errors.shippingInfo.district && <div className="text-danger fs-12">{errors.shippingInfo.district}</div>}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="form-group mb-3 row">
                                                                                                                            <label className="col-lg-4 col-form-label" htmlFor="val-shipping-ward">Ward<span className="text-danger">*</span></label>
                                                                                                                            <div className="col-lg-7">
                                                                                                                                <select className="form-control" value={formShipping.locCodes.wardCode} onChange={(e) => chooseShippingWard(e.target.value)}>
                                                                                                                                    <option value="">Please select</option>
                                                                                                                                    {shippingWards.length > 0 && (
                                                                                                                                        (shippingWards.map((ward, i) => {
                                                                                                                                            return (<option value={ward.CodeWard} key={i}>{formatDistrictWard(ward.NameWard, LocationFormat.WARD_NUMBER_PREFIX_VN)}</option>);
                                                                                                                                        }))
                                                                                                                                    )}
                                                                                                                                </select>
                                                                                                                                {errors.shippingInfo.ward && <div className="text-danger fs-12">{errors.shippingInfo.ward}</div>}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                )}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </form>
                                                                                            ) : ''}
                                                                                        </div>
                                                                                    ))}
                                                                                </> : <></>}
                                                                            </Tab.Pane>
                                                                        ))}
                                                                    </Tab.Content>
                                                                </Tab.Container>
                                                            </div>
                                                        </Card.Body>
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Card>
                                        </Tab.Container>
                                    </Col>
                                </Row>
                                <div className="form-group row">
                                    <div className="col-xl-12 text-end">
                                        <button className="btn btn-primary" onClick={handleSubmitInfo}>Update Customer</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {loading && (<div className="dialog-blur">{<Preloader />}</div>)}
                {displayFileURL && (
                    <div className="dialog-blur">
                        <div className="dialog-content">
                            <div className="text-end">
                                <span className='me-5'>
                                    <a onClick={closeToggle} href={displayFileURL} target='blank' className='text-info'><i className="fa fa-download"></i>Download File</a>
                                </span>
                                <span onClick={closeToggle} className='text-black'><i className="fa fa-times"></i> Close</span>
                            </div>
                            <hr />
                            <img src={displayFileURL} alt="" />
                        </div>
                    </div>
                )}
            </div >
        </>
    )
}

export default EditVendorCustomer;