import { CountryCodes } from "../constant/AppConstant";

export class ProfileInfoModel {
    constructor() {
        this.companyName = '';
        this.mmcNo = '';
        // Extra fields
        this.ssmNo = '';
        this.tinNo = '';
        this.msicNo = '';
        this.soldToCustomerCode = '';
        this.shipToCustomerCode = '';
        // Business license
        this.businessLicenseNo = '';
        this.businessLicenseDateOfIssue = '';
        this.businessLicensePlaceOfIssue = '';
        this.businessLicenseURL = '';
        // Pharma business license
        this.pharmaBusinessLicenseNo = '';
        this.pharmaBusinessLicenseDateOfIssue = '';
        this.pharmaBusinessLicensePlaceOfIssue = '';
        this.pharmaBusinessLicenseURL = '';
        // GDP / GPP / GSP license
        this.gdpGppGspDateOfIssue = '';
        this.gdpGppGspURL = '';
        this.treatmentLicenseURL = '';
        this.address = '';
        this.ward = '';
        this.district = '';
        this.city = '';
        this.state = '';
        this.country = 'Malaysia';
        this.postalCode = '';
        this.locCodes = new LocCodesModel();
    }

    static validate(obj, error) {
        let result = true;

        if (obj.companyName == null || obj.companyName === '') {
            error.companyName = 'Company Name is required';
            result = false;
        }
        if (obj.address == null || obj.address === '') {
            error.address = 'Address is required';
            result = false;
        }
        if (obj.city == null || obj.city === '') {
            error.city = 'City is required';
            result = false;
        }
        if (obj.state == null || obj.state === '') {
            error.state = 'State is required';
            result = false;
        }
        if (obj.postalCode == null || obj.postalCode === '') {
            error.postalCode = 'Postal Code is required';
            result = false;
        }
        if (obj.mmcNo == null || obj.mmcNo === '') {
            error.mmcNo = 'MMC Number is required';
            result = false;
        }
        if (obj.ssmNo == null || obj.ssmNo === '') {
            error.ssmNo = 'SSM Number is required';
            result = false;
        }
        if (obj.tinNo == null || obj.tinNo === '') {
            error.tinNo = 'TIN Number is required';
            result = false;
        }
        if (obj.msicNo == null || obj.msicNo === '') {
            error.msicNo = 'MSIC Code is required';
            result = false;
        }
        if (obj.soldToCustomerCode == null || obj.soldToCustomerCode === '') {
            error.msicNo = 'Sold to Customer Code is required';
            result = false;
        }
        if (obj.shipToCustomerCode == null || obj.shipToCustomerCode === '') {
            error.msicNo = 'Ship to Customer Code is required';
            result = false;
        }
        // if (obj.businessLicenseNo == null || obj.businessLicenseNo === '') {
        //     error.businessLicenseNo = 'Business License No is required';
        //     result = false;
        // }
        // if (obj.pharmaBusinessLicenseNo == null || obj.pharmaBusinessLicenseNo === '') {
        //     error.pharmaBusinessLicenseNo = 'Pharma Business License No is required';
        //     result = false;
        // }

        return result;
    }
};

export class BillingInfoModel {
    constructor() {
        this.name = '';
        this.taxCode = '';
        this.address = '';
        this.ward = '';
        this.district = '';
        this.city = '';
        this.state = '';
        this.postalCode = '';
        this.locCodes = new LocCodesModel();
    }

    static validate(obj, error) {
        let result = true;

        if (obj.name == null || obj.name === '') {
            error.name = 'Name is required';
            result = false;
        }
        // if (obj.taxCode == null || obj.taxCode === '') {
        //     error.taxCode = 'Tax Code is required';
        //     result = false;
        // }
        if (obj.address == null || obj.address === '') {
            error.address = 'Address is required';
            result = false;
        }
        // if (obj.city == null || obj.city === '') {
        //     error.city = 'City is required';
        //     result = false;
        // }
        if (obj.state == null || obj.state === '') {
            error.state = 'State is required';
            result = false;
        }
        if (obj.postalCode == null || obj.postalCode === '') {
            error.postalCode = 'Postal Code is required';
            result = false;
        }

        return result;
    }
};

export class ShippingInfoModel {
    constructor() {
        this.contactName = '';
        this.contactNumber = '';
        this.address = '';
        this.ward = '';
        this.district = '';
        this.city = '';
        this.state = '';
        this.country = 'Malaysia';
        this.postalCode = '';
        this.locCodes = new LocCodesModel();
    }

    static validate(obj, error, onlyCheckCity = false) {
        let result = true;

        if (obj.contactName == null || obj.contactName === '') {
            error.contactName = 'Contact Name is required';
            result = false;
        }
        if (obj.contactNumber == null || obj.contactNumber === '') {
            error.contactNumber = 'Contact Number is required';
            result = false;
        }
        if (obj.address == null || obj.address === '') {
            error.address = 'Address is required';
            result = false;
        }
        if (obj.city == null || obj.city === '') {
            error.city = 'City is required';
            result = false;
        }
        // if (!onlyCheckCity) {
        //     if (obj.district == null || obj.district === '') {
        //         error.district = 'District is required';
        //         result = false;
        //     }
        //     if (obj.ward == null || obj.ward === '') {
        //         error.ward = 'Ward is required';
        //         result = false;
        //     }
        // }
        if (obj.state == null || obj.state === '') {
            error.state = 'State is required';
            result = false;
        }
        if (obj.postalCode == null || obj.postalCode === '') {
            error.postalCode = 'Postal Code is required';
            result = false;
        }

        return result;
    }
};

export class LocCodesModel {
    constructor() {
        this.businessLicensePOICode = '';
        this.pharmaBusinessLicensePOICode = '';
        this.wardCode = '';
        this.districtCode = '';
        this.cityCode = '';
        this.stateCode = '';
        this.countryCode = CountryCodes.MALAYSIA_COUNTRY_CODE;
    }
};